import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {I18NEXT_SERVICE, I18NextModule, ITranslationService} from 'angular-i18next';
import XHR from 'i18next-xhr-backend';
import {TranslationService} from './services/translation/translation.service';
import { TranslatePipe } from './pipes/translate/translate.pipe';
import setTranslationOptions from './config/translation.config';


export function appInit(i18next: ITranslationService) {
  return () => i18next.use(XHR).init(setTranslationOptions({lng: 'en', fallbackLng: 'en', ns: 'en-GB'}));
}

export function localeIdFactory(i18next: ITranslationService)  {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true
  },
  {
    provide: LOCALE_ID,
    deps: [I18NEXT_SERVICE],
    useFactory: localeIdFactory
  }];

@NgModule({
  declarations: [
    TranslatePipe
  ],
  exports: [
    TranslatePipe
  ],
  imports: [
    CommonModule,
    I18NextModule.forRoot(),
  ],
  providers: [
    I18N_PROVIDERS,
    TranslationService,
  ]
})
export class TranslationModule { }
