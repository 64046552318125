import {Pipe, PipeTransform, Sanitizer, SecurityContext} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'parseSafeHtml'
})
export class ParseSafeHtmlPipe implements PipeTransform {
  private _sanitizer: DomSanitizer;
  constructor(sanitizer: DomSanitizer) {
    this._sanitizer = sanitizer;
  }

  public transform(input: { default: string }): any {
    return this._sanitizer.bypassSecurityTrustHtml(input.default);
  }

}
