import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../core/core.module';
import { PlayersStatsComponent } from './business-components/players-stats/players-stats.component';
import {TopPlayersComponent} from './presentation-components/top-players/top-players.component';
import {PlayersListComponent} from './presentation-components/players-list/players-list.component';
import {UxModule} from '../ux/ux.module';
import {TranslationModule} from '../translation/translation.module';
import {PlayersService} from './services/players/players.service';
import {AnimationsModule} from '../animations/animations.module';
import { PlayerComponent } from './presentation-components/player/player.component';
import {TopPlayersListsComponent} from './top-players-list/top-players-lists.component';
import { ListsComponent } from './business-components/lists/lists.component';
import {PlayerCardComponent} from './presentation-components/player-card/player-card.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import { ClubComponent } from './business-components/club/club.component';
import { SearchComponent } from './business-components/search/search.component';
import {SearchService} from "./services/search/search.service";

@NgModule({
  declarations: [
    ListsComponent,
    PlayersStatsComponent,
    TopPlayersComponent,
    PlayersListComponent,
    PlayerComponent,
    PlayerCardComponent,
    TopPlayersListsComponent,
    ClubComponent,
    SearchComponent
  ],
  exports: [
    ListsComponent,
    PlayersStatsComponent,
    TopPlayersComponent,
    PlayersListComponent,
    PlayerCardComponent,
    SearchComponent
  ],
  imports: [
    AnimationsModule,
    CommonModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    TranslationModule,
    UxModule
  ],
  providers: [
    PlayersService,
    SearchService
  ]
})
export class StatsModule { }
