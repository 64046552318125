import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fplh-logo-loading-indicator',
  templateUrl: './logo-loading-indicator.component.html',
  styleUrls: ['./logo-loading-indicator.component.scss']
})
export class LogoLoadingIndicatorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
