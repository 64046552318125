import {IPlayerClub, IPlayerInstance, IPlayerPosition} from './interfaces/player-instance.interface';

export class PlayerInstance implements IPlayerInstance {
  public assists: number;
  public cleanSheets: number;
  public club: IPlayerClub;
  public code: number;
  public cost: number;
  public creativity: number;
  public displayName: string;
  public firstName: string;
  public form: number;
  public gameWeekPoints: number;
  public goalsConceded: number;
  public goalsScored: number;
  public id: string;
  public image: string;
  public influence: number;
  public lastName: string;
  public points: number;
  public position: IPlayerPosition;
  public threat: number;
  public uniqueId: number;

  constructor() {
  }

  public getPosition(): string {
    return this.position && this.position.name ? this.position.name : 'unknown';
  }

  public getImage(): string {
    // console.log('GETTING_IMAGE: ', this.image);
    return this.image;
  }

  public getFullDisplayName(): string {
    return this.firstName + ' ' + this.lastName;
  }

}
