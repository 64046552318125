import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class Api {
  private _http: HttpClient;
  private _baseUrl = environment.API_URL;

  constructor(http: HttpClient) {
    this._http = http;
  }

  public get(url: string, params?: any): Observable<any> {
    let headers = new HttpHeaders();

    headers = headers.set(
      'Access-Control-Allow-Origin', '*'
    ).set(
      'Content-Type', 'application/json'
    ).set(
      'Accept', 'application/json'
    ).set(
      'X-Api-Access', environment.XApiAccess
    );

    return this._http.get(`${this._baseUrl}${url}`, {headers, params});
  }

  public getImage(url: string): Observable<any> {
    return this._http.get(url, { responseType: 'blob' });
  }

  public postToForm(url: string, payload: any): Observable<any> {
    return this._http.post(`${this._baseUrl}${url}`, payload);
  }

  public post(url: string, payload: any, bearer = false): Observable<any> {
    let headers = new HttpHeaders();

    headers = headers.set(
      'Access-Control-Allow-Origin', '*'
    ).set(
      'Accept', 'application/json'
    ).set(
      'Content-Type', 'application/json'
    );

    if (bearer) {
      const token = localStorage.getItem('id_token');

      headers.set('Authorization', `bearer ${token}`);
    }

    return this._http.post(`${this._baseUrl}${url}`, JSON.stringify(payload), {headers});
  }
}
