import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FooterService {
  constructor() {}

  public positionBottom(): void {
    const el = document.getElementsByClassName('Footer-container')[0];

    if (el) {
      el.classList.add('Footer--bottom');
    }
  }

  public resetPosition(): void {
    const el = document.getElementsByClassName('Footer-container')[0];

    if (el) {
      el.classList.remove('Footer--bottom');
    }
  }
}
