import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import {IMenuConfig} from './modules/shared/presentation-components/menu/menu.interface';
import {TranslationService} from './modules/translation/services/translation/translation.service';
import {NavigationManagementService} from './modules/shared/services/navigation-management/navigation-management.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'fplh-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public title = 'Fantasy Premier League Helper';
  public menuConfig: IMenuConfig;
  public isActionDisabled = false;

  // public removeMargin = false;

  private _location: Location;
  private _activatedRoute: ActivatedRoute;
  private _router: Router;
  private _translationService: TranslationService;
  private _nms: NavigationManagementService;
  private _navConfigSubscription: Subscription;
  private _navJammerSubscription: Subscription;
  private _checkRouteChangeOnFatalError = false;

  constructor(
    location: Location,
    router: Router,
    translationService: TranslationService,
    nms: NavigationManagementService
  ) {
    this._location = location;
    this._router = router;
    this._translationService = translationService;
    this._nms = nms;
  }

  public ngOnInit() {
    this.menuConfig = this._setMenuConfig();

    this._navConfigSubscription = this._nms.navigationConfigSubject$.subscribe( (config: {disableAllNavigation: boolean} | null): void => {
      if (config) {
        this.isActionDisabled = config.disableAllNavigation;
      }
    });
  }

  public ngOnDestroy(): void {
    if (this._navConfigSubscription) {
      this._navConfigSubscription.unsubscribe();
    }
  }

  public navigateTo(route: string) {
    this._router.navigateByUrl(route).then(
      (success: boolean): void => {
      },
      (): void => {

      }
    );
  }

  public goHome() {
    this.navigateTo('/');
  }

  private _setMenuConfig(): IMenuConfig {
    return {
      items: [
        {
          name: this._translationService.translate({translateId: 'menu.home'}),
          action: (): void => {
            this.goHome();
          }
        },
        {
          name: this._translationService.translate({translateId: 'menu.lists'}),
          action: (): void => {
            this.navigateTo('players/lists');
          }
        },
        {
          name: this._translationService.translate({translateId: 'menu.search'}),
          action: (): void => {
            this.navigateTo('search');
          }
        }
      ]
    };
  }
}

