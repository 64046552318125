import { Injectable } from '@angular/core';
import { ISearchDictionary } from './search.service.interface';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class SearchService {
  public dictionary: ISearchDictionary;
  public keyWords: string[];
  public onSearchObservable: Observable<boolean>;
  public onSearchSubject = new BehaviorSubject(false);
  constructor() {
    this.dictionary = this._setDictionary();
    this.keyWords = this._setKeyWordsToMatch();
    this.onSearchObservable = this.onSearchSubject.asObservable();
  }

  public matchKeyWord(value: string): string {
    // Continue here: Create SearchDictionaryService

    if (value) {
      const matcher = this.dictionary;
      const matched = this.keyWords.filter((v: string): boolean => {
        return value.includes(v);
      })[0];

      return matcher[matched] || '';
    }

    return '';
  }

  public getIntents(value: string): any {
    const intents = [];
    const pointsIntent = this._getPointsIntent(value);
    const goalsIntent = this._getGoalsIntent(value);
    const formIntent = this._getFormIntent(value);

    if (pointsIntent !== null) {
      intents.push(pointsIntent);
    }

    if (goalsIntent !== null) {
      intents.push(goalsIntent);
    }

    if (formIntent !== null) {
      intents.push(formIntent);
    }

    return intents;
  }

  private _setDictionary(): ISearchDictionary {
    return {
      cleansheet: 'cleansheets',
      cleansheets: 'cleansheets',
      pts: 'points',
      point: 'points',
      points: 'points',
      goal: 'goals',
      goals: 'goals'
    };
  }

  private _setKeyWordsToMatch(): string[] {
    return [
      'points', 'point', 'form', 'goals', 'goal',
      'cleansheets', 'cleansheet'
    ];
  }

  private _getPointsIntent(value: string): any {
    let intent: any = null;
    const hasPoints = value.includes('points');
    const hasPoint = value.includes('point');
    const hasPts = value.includes('pts');

    if ( hasPoints || hasPoint || hasPts) {
      let key = 'goals';

      if (hasPoint) {
        key = 'point';
      }

      if (hasPts) {
        key = 'pts';
      }

      intent = this._intentBuilder(value, 'points', key);
    }

    return intent;
  }

  private _getGoalsIntent(value: string): any {
    let intent = null;
    const hasGoals = value.includes('goals');
    const hasGoal = value.includes('goal');
    const hasGls = value.includes('gls');

    if ( hasGoals || hasGoal || hasGls) {
      let key = 'goals';

      if (hasGoal) {
        key = 'goal';
      }

      if (hasGls) {
        key = 'gls';
      }

      intent = this._intentBuilder(value, 'goals', key);
    }

    return intent;
  }

  private _getFormIntent(value: string): any {
    let intent: any = null;
    const hasForm = value.includes('form');
    const hasFrm = value.includes('frm');

    if ( hasForm || hasFrm ) {
      let key = 'form';

      if (hasFrm) {
        key = 'frm';
      }

      intent = this._intentBuilder(value, 'form', key);
    }

    return intent;
  }

  private _intentBuilder(value: string, name: string, regExKey?: string): any {
    let intent: any = null;
    const equalsRegexp = new RegExp(`${name}=([0-9]+)`, 'g'); // /(points=([0-9]+))\+/g; if the plus is included
    const wordPostFixedNumber = new RegExp(`(${regExKey})(\\d+)|(${regExKey}) (\\d+)`, 'g');
    const wordPreFixedNumber = new RegExp(`((\\d+) ${regExKey})|((\\d+)${regExKey})`, 'g');
    const matchedEqualSign = value.match(equalsRegexp);
    const matchedPostFixedNumber =  value.match(wordPostFixedNumber)?.join('') || null; // value.match();
    const matchedPreFixedNumber = value.match(wordPreFixedNumber)?.join('') || null;
    const attribute: string = name;

    if (matchedPostFixedNumber != null && !matchedPreFixedNumber) {
      const postFixFiltered: string[] | null = matchedPostFixedNumber.trim().split(/(\d+)/)?.filter(v => !!v );
      const postFixNumValue = parseInt(postFixFiltered[1], 10);

      intent = {
        attribute,
        value: postFixNumValue
      };
    }

    if (matchedPreFixedNumber != null && !matchedPostFixedNumber) {
      const filtered: string[] | null = matchedPreFixedNumber.trim().split(/(\d+)/)?.filter(v => !!v );
      const numberValue = parseInt(filtered[0], 10);

      intent = {
        attribute,
        value: numberValue
      };
    }

    if (matchedEqualSign !== null) {
      const ptsArr = matchedEqualSign[0].split('=');
      const pts = ptsArr[0];
      const numString = ptsArr[1];
      const num = parseInt(numString, 10);

      intent = {
        attribute: pts,
        value: num
      };
    }

    return intent;
  }
}
