import { Component, Input, OnInit } from '@angular/core';
import { IImageConfig } from './image.component.interface';

@Component({
  selector: 'fplh-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

  @Input()
  public config: IImageConfig;
  public image = 'assets/images/players/placeholder.png';
  public fixedDimensions = false;
  public showPlaceholder = true;

  public ngOnInit() {
    this.image = 'assets/images/players/' + this.config.src;
  }

  public onImageError(): void {
    this.image = 'assets/images/players/placeholder.png';
  }
}
