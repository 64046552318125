import {Component, Input, OnInit} from '@angular/core';
import {ITableBodyItem, ITableComponent, ITableConfig, ITableConfigHeaderItem, ITableHeader} from './interfaces/table.interface';
@Component({
  selector: 'fplh-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements ITableComponent, OnInit {
  @Input()
  public config: ITableConfig;

  public isAscending = false;
  public sortByField: string;
  public showArrowUp: boolean;

  public ngOnInit(): void {
    const filteredSort = this.config.header.items.filter(
      (header: ITableConfigHeaderItem): boolean => {
        return header.sorted;
      }
    )[0];
    this.sortByField = filteredSort.fieldId;

    if (!this.isAscending) {
      this.showArrowUp = true;
    } else {
      this.showArrowUp = false;
    }
  }

  public onImageError(item: any): void {
    item.image = 'placeholder.png';
  }

  public sortBy(header: ITableConfigHeaderItem): void {
    this.isAscending = !this.isAscending;
    this.sortByField = header.fieldId;
    this.sortTableItems();

    if (this.isAscending && header.type === 'string') {
      this.showArrowUp = true;
    } else {
      this.showArrowUp = !this.isAscending && header.type !== 'string';
    }
  }

  // REFACTOR: to be used in the near future
  public trackByFn = (index: number): number => {
    return index;
  }

  public setMobileFieldName(fieldId: string): string {
    if (fieldId === 'name') {
      return 'Name';
    }

    if (fieldId === 'points') {
      return 'Points';
    }

    if (fieldId === 'cost') {
      return 'Cost';
    }

    if (fieldId === 'form') {
      return 'Form';
    }

    if (fieldId === 'goalsScored') {
      return 'Goals Scored';
    }
  }

  private sortTableItems(): void {
    this.config.body.items.sort(
      (a: ITableBodyItem, b: ITableBodyItem): number => this._sortItems(a, b)
    );
  }

  private _sortItems(a: ITableBodyItem, b: ITableBodyItem): number {
    if (typeof a[this.sortByField] === 'string') {
      return this.isAscending ? (a[this.sortByField] as string).localeCompare((b[this.sortByField] as string)) :
        (b[this.sortByField] as string).localeCompare((a[this.sortByField] as string));
    }

    if (typeof a[this.sortByField] === 'number') {
      return this.isAscending ? (a[this.sortByField] as number) - (b[this.sortByField] as number) :
        (b[this.sortByField] as number) - (a[this.sortByField] as number);
    }

  }
}
