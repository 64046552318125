<div class="TopPlayers-container">
  <fplh-content-nav-bar [config]="contentNavBarConfig"></fplh-content-nav-bar>

  <div class="TopPlayers-cardsContainer">
    <div class="TopPlayers-topPlayerCardsContainer"
         *ngIf="!viewMoreTopPlayer.length else showTopFive">
      <fplh-player-card
              *ngFor="let player of config; let index=index; let even=even;"
              class="TopPlayers-card"
              [class.isEven]="even"
              [config]="{
                player: player,
                actions: {
                  viewMorePlayers: viewMorePlayers,
                  onCardClick: onCardClick
                },
                mostPointsScored: mostPointsScored,
                settings: {
                  showPositionLabel: true
                }
              }"></fplh-player-card>
    </div>

    <ng-template #showTopFive>
      <div class="TopPlayers-topPlayerCardsContainer" *ngIf="viewMoreTopPlayer.length">
        <fplh-player-card
                class="TopPlayers-card TopPlayers--topFive"
                *ngFor="let player of viewMoreTopPlayer"
                [config]="{
                actions: {
                  onCardClick: onCardClick
                },
                player: player,
                mostPointsScored: mostPointsScored,
                settings: {
                  isTopFive: true,
                  showPositionLabel: true
                }
              }"></fplh-player-card>
      </div>
    </ng-template>
  </div>
</div>
