import {Routes} from '@angular/router';
import {UpdatePlayersComponent} from '../../../admin/business-components/update-players/update-players.component';

// REFACTOR: Add admin canGuard
export const adminRoutes: Routes = [
  {
    path: 'admin',
    component: UpdatePlayersComponent,
  }
];
