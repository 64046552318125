import {Component, Input, OnInit} from '@angular/core';
import {IRightPaneConfig, IRightPaneTemplateData} from './interfaces/right-pane.interface';
import {PlayerInstance} from '../../../../stats/player-instance/player-instance';
import {IPlayerInstance} from '../../../../stats/player-instance/interfaces/player-instance.interface';
import {PlayersService} from '../../../../stats/services/players/players.service';
import {Router} from '@angular/router';
import {IClubTotal} from '../../../../shared/interfaces/shared.interface';

@Component({
  selector: 'fplh-right-pane',
  templateUrl: './right-pane.component.html',
  styleUrls: ['./right-pane.component.scss']
})
export class RightPaneComponent implements OnInit {
  @Input()
  public config: IRightPaneConfig;
  public contentNavBarConfig: any;
  public contentNavBarClubsTotalConfig: any;
  public templateData: IRightPaneTemplateData[]; // Refactor: use proper interface

  private _playersService: PlayersService;
  private _router: Router;

  constructor(playersService: PlayersService, router: Router) {
    this._playersService = playersService;
    this._router = router;
  }

  public ngOnInit(): void {
    if (this.config && this.config.topStats) {
      this._setContentNavBarConfig();
      this._setTemplateData();
    }
  }

  public onImageError(player: PlayerInstance): void {
    if (player) {
      player.image = 'placeholder.png';
    }
  }

  private _setTemplateData(): void {
    this.templateData = [
      {
        label: 'Assists',
        player: this.config.topStats.assists,
        translateId: 'topPlayers.player.assists',
        stat: this.config.topStats.assists.assists
      },
      {
        label: 'Clean sheets defender',
        player: this.config.topStats.mostCleanSheetsDEF,
        translateId: 'topPlayers.player.mostCleanSheetsDEF',
        stat: this.config.topStats.mostCleanSheetsDEF.cleanSheets
      },
      {
        label: 'Clean sheets Goalkeeper',
        player: this.config.topStats.mostCleanSheetsGK,
        translateId: 'topPlayers.player.mostCleanSheetsGK',
        stat: this.config.topStats.mostCleanSheetsGK.cleanSheets
      },
      {
        label: 'Goals',
        player: this.config.topStats.mostGoals,
        translateId: 'topPlayers.player.mostGoals',
        stat: this.config.topStats.mostGoals.goalsScored
      },
      {
        label: 'Goals',
        player: this.config.topStats.inform,
        translateId: 'topPlayers.player.inform',
        stat: this.config.topStats.inform.form
      }
    ];
  }

  private _setContentNavBarConfig(): void {
    this.contentNavBarConfig = {
      heading: 'topPlayers.player.heading',
      subHeading: 'topPlayers.player.subHeading'
    };

    // Continue here - check translations match
    // Commit and launch app live
    this.contentNavBarClubsTotalConfig = {
      heading: 'clubsTotal.heading',
      subHeading: 'clubsTotal.subHeading'
    };
  }

  public navigateTo(player: IPlayerInstance) {
    this._playersService.playerDetails = player;
    this._router.navigateByUrl('/players/player/details').then(
      (success: boolean): void => {
      },
      (): void => {

      }
    );
  }

  public goToClub(t: IClubTotal): void {
    this._router.navigateByUrl(`/club/${t.club.id}`).then(
      (success: boolean): void => {
      },
      (): void => {

      }
    );
  }
}
