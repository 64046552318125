import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormDataService {

  constructor() { }

  public formData(data: Object): FormData {
    const formData: FormData = new FormData();

    const objectKeys: string[] = Object.keys(data);

    if (objectKeys.length) {
      for (let i = 0; i < objectKeys.length; i++) {
        const key: string = objectKeys[i];

        formData.append(key, data[key]);
      }
    }

    return formData;
  }
}
