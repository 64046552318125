import {Component, Input, OnInit} from '@angular/core';
import icons from '../../../../../assets/icons-config/icons';

@Component({
  selector: 'fplh-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  @Input()
  public config: any;

  public icon: any;

  public ngOnInit() {
    this.icon = icons[this.config.name];
  }

}
