import {Component, Input, OnInit} from '@angular/core';
import {IPlayerCardConfig} from './interfaces/player-card.interface';

@Component({
  selector: 'fplh-player-card',
  templateUrl: './player-card.component.html',
  styleUrls: ['./player-card.component.scss']
})
export class PlayerCardComponent implements OnInit {
  @Input()
  public config: IPlayerCardConfig;
  constructor() { }

  public ngOnInit() {}
}
