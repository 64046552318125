<div class="UpdatePlayers-container">
  <div class="UpdatePlayers-loadingBackdrop" *ngIf="isLoading">
    <fplh-loading-spinner
      class="UpdatePlayers-spinner"
      [colorScheme]="'colorGreyLight'"></fplh-loading-spinner>
  </div>
  <p class="UpdatePlayers-errorMessage" *ngIf="showErrorMessage">{{errorMessage}}</p>
  <div class="UpdatePlayers-formContainer"
       *ngIf="!isLoggedIn">
    <div class="UpdatePlayers-headingContainer">
      <h1 class="UpdatePlayers-heading">WELCOME</h1>
    </div>
    <form class="UpdatePlayers-form" action="">
      <div class="UpdatePlayers-usernameContainer">
        <input class="UpdatePlayers-usernameInput"
               type="text"
               placeholder="Username"
               name="username"
               [(ngModel)]="loginModel.username" />
      </div>
      <div class="UpdatePlayers-passwordContainer">
        <input class="UpdatePlayers-passwordInput"
               type="password"
               placeholder="Password"
               name="password"
               [(ngModel)]="loginModel.password">
      </div>
      <div class="UpdatePlayers-buttonContainer">
        <button class="UpdatePlayers-loginButton" (click)="login()">Login</button>
      </div>
    </form>
  </div>
  <div class="UpdatePlayers-actionContainer" *ngIf="isLoggedIn">
    <div class="UpdatePlayers-actions">
      <div class="UpdatePlayers-updateActionsContainer">
        <button class="UpdatePlayers-updateAction" (click)="updateClubs()">Update Clubs</button>
        <button class="UpdatePlayers-updateAction" (click)="updatePositions()">Update Positions</button>
      </div>
      <button class="UpdatePlayers-updateButton UpdatePlayers-action" (click)="updatePlayerData()">Update Players</button>
      <button class="UpdatePlayers-signOutButton UpdatePlayers-action" (click)="signOut()">Log Out</button>
    </div>
  </div>
</div>
