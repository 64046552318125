import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {Api} from './api/core.api';
import {CoreModel} from './model/core.model';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    Api,
    CoreModel
  ]
})
export class CoreModule { }
