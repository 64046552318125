import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MenuComponent} from './presentation-components/menu/menu.component';
import {FixedOnScrollDirective} from './directives/fixed-on-scroll/fixed-on-scroll.directive';
import { MenuIconSvgComponent } from './presentation-components/menu/menu-icon-svg/menu-icon-svg.component';
import {CoreModule} from '../core/core.module';
import {UxModule} from '../ux/ux.module';
import { AppContainerCssOverrideDirective } from './directives/app-container-css-override.directive/app-container-css-override.directive';
import {FormDataService} from './services/form-data/form-data.service';
import {NavigationManagementService} from './services/navigation-management/navigation-management.service';
import { FooterComponent } from './presentation-components/footer/footer.component';
import {FooterService} from './services/footer/footer.service';



@NgModule({
  declarations: [
    FixedOnScrollDirective,
    MenuComponent,
    MenuIconSvgComponent,
    AppContainerCssOverrideDirective,
    FooterComponent
  ],
  exports: [
    FixedOnScrollDirective,
    MenuComponent,
    MenuIconSvgComponent,
    AppContainerCssOverrideDirective,
    FooterComponent
  ],
  providers: [
    FormDataService,
    NavigationManagementService,
    FooterService
  ],
  imports: [
    CommonModule,
    CoreModule,
    UxModule
  ]
})
export class SharedModule { }
