import { Component, Input, OnInit } from '@angular/core';
import { IMenuConfig, IMenuItem } from './menu.interface';

import { enterLeaveAnimation, fadeInOutAnimation } from '../../../animations/services/animations/animations.triggers';

@Component({
  selector: 'fplh-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    enterLeaveAnimation,
    fadeInOutAnimation
  ]
})
export class MenuComponent implements OnInit {
  @Input()
  public config: IMenuConfig;
  public menuItems: IMenuItem[] = [];
  public showMenu = false;
  public showMenuIcon = true;
  public showCloseIcon = false;
  public slideInMenu = false;

  constructor() { }

  public ngOnInit(): void {
    if (this.config && this.config.items && this.config.items.length) {
      this.menuItems = this.config.items;
      // this._extractChildrenItems();
    }
  }

  public onMenuClick(): void {
    this.showMenu = !this.showMenu;
    this.showMenuIcon = !this.showMenuIcon;
    this.showCloseIcon = !this.showCloseIcon;

    setTimeout((): void => {
      this.slideInMenu = this.showMenu;
    }, 0);
  }

  public performMenuItemAction(item: IMenuItem): void {
    item.action();
    this.onMenuClick();
  }
}
