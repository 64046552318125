import { Component, OnInit } from '@angular/core';
import { CoreModel } from '../../../core/model/core.model';
import { Router } from '@angular/router';
import { Admin } from '../../../../enums/admin.enums';
import { IPlayersList } from '../../../core/model-instance/interfaces/model-instance.interfaces';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { ILogin } from '../../../auth/interfaces/auth.interface';

@Component({
  selector: 'fplh-update-players',
  templateUrl: './update-players.component.html',
  styleUrls: ['./update-players.component.scss']
})
export class UpdatePlayersComponent implements OnInit {

  public isLoggedIn = false;
  public isLoading = false;
  public loginModel: ILogin = {username: '', password: ''};
  public errorMessage = 'An error has occurred, unable to login';
  public showErrorMessage = false;

  private _model: CoreModel;
  private _router: Router;
  private _usernameIsValid = false;
  private _taskIsValid = false;
  private _authService: AuthService;

  constructor(model: CoreModel, router: Router, authService: AuthService) {
    this._model = model;
    this._router = router;
    this._authService = authService;
    this.isLoggedIn = this._authService.isLoggedIn();
  }

  public async ngOnInit() {
    const url: string = this._router.url;
    this._usernameIsValid = url.includes(Admin.USERNAME);
    this._taskIsValid = url.includes(Admin.TASK);

    if ((!this._usernameIsValid && !this._taskIsValid) || !this._usernameIsValid || !this._taskIsValid) {
      await this._goHome();
    }
  }

  public updatePlayerData(): void {
    this.isLoading = true;
    if (this._usernameIsValid && this._taskIsValid) {
      this._model.post(
        'update/players?name=administrator&task=updateplayers', {}
      ).subscribe(
        (response: any): void => {
          // REFACTOR: After update replace cached json
          // clear cache or update
          // you can call the top list api to get the exact model as the same as in session storage

          // REFACTOR: Monitor every session storage so that it is not affected by this
          sessionStorage.removeItem('DATA');
          sessionStorage.removeItem('player-details');
          this._getPlayers();

        },
        (error: string): void => {
          this.isLoading = false;
        }
      );
    }
  }

  public login(): void {
    this.isLoading = true;
    this._authService.login(this.loginModel).subscribe(
      (isSuccessful: boolean): void => {
        this.isLoading = false;
        this.isLoggedIn = isSuccessful;
      },
      (error: any): void => {
        this.errorMessage = error;
        this.showErrorMessage = true;
        this.isLoading = false;
      }
    );
  }

  private async _goHome(): Promise<void> {
    await this._router.navigateByUrl('/');
    this.signOut();
  }

  private _getPlayers(): void {
    this._model.get<IPlayersList>(
      'Players/Top',
      true
    ).subscribe(
      (): void => {
        this.isLoading = false;
        this._goHome().then(
          (): void => {
          },
          (): void => {
          }
        );
      },
      (): void => {

      }
    );
  }

  public updateClubs(): void {
    this._model.post('update/clubs', {}).subscribe(
      (resp: any): void => {
      },
      (): void => {

      }
    );
  }

  public updatePositions(): void {
    this._model.post('update/positions', {}).subscribe(
      (resp: any): void => {
      },
      (): void => {

      }
    );
  }
  public signOut(): void {
    this._authService.logout().subscribe(
      (): void => {
        this.isLoggedIn = this._authService.isLoggedIn();
      },
      (): void => {
        // REFACTOR: Handle error
      }
    );
  }
}
