<div class="RightPane-container">
    <fplh-content-nav-bar [config]="contentNavBarConfig"></fplh-content-nav-bar>
    <div class="RightPane-cardsContainer">
        <button class="RightPane-action RightPane-card"
                *ngFor="let data of templateData"
                (click)="navigateTo(data.player)">
            <div class="RightPane-playerBio">
                <div class="RightPane-imageContainer">
                  <fplh-image class="RightPane-image" [config]="{src: data.player.getImage(), rounded: true}"></fplh-image>
<!--                    <img class="RightPane-image" [src]="data.player.getImage()" (error)="onImageError(data.player)" alt="Photo of a player">-->
                </div>
                <div class="RightPane-topStatsContainer">
                    <div class="RightPane-statContainer">
                        <h1 class="RightPane-statHeading">{{data.translateId | translate}}</h1>
                        <span class="RightPane-playerName">{{data.player.getFullDisplayName()}}</span>
                        <span class="RightPane-statSubHeading">{{data.stat}}</span>
                    </div>
                </div>
            </div>
        </button>
    </div>
  <fplh-content-nav-bar [config]="contentNavBarClubsTotalConfig"></fplh-content-nav-bar>
    <div class="RightPane-clubsTotalContainer">
      <button class="RightPane-clubAction" *ngFor="let clubTotal of config.clubTotals"
              (click)="goToClub(clubTotal)">
        <div class="RightPane-clubImageContainer">
          <img class="RightPane-clubImage" [src]="'assets/images/clubs/' + clubTotal.club.name + '.png' " [alt]="clubTotal.club.name">
        </div>
        <span class="RightPane-clubName">{{clubTotal.club.name}}</span>
        <span class="RightPane-clubPoints">{{clubTotal.points}} points</span>
      </button>
    </div>
</div>
