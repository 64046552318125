import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PlayersService} from '../../services/players/players.service';
import {trigger} from '@angular/animations';
import {AnimationsHelper} from '../../../animations/services/animations/helper/animations-helper.service';
import fadeInAnimation from '../../../animations/services/animations/defaults/fadeIn.animation';
import {Router} from '@angular/router';
import {IContentNavBarConfig} from '../../../ux/presentation-components/content-nav-bar/interfaces/content-nav-bar.interface';
import {PlayerInstance} from '../../player-instance/player-instance';
import {IPlayerInstance} from '../../player-instance/interfaces/player-instance.interface';

const animationTrigger: any = {};

@Component({
  selector: 'fplh-top-players',
  templateUrl: './top-players.component.html',
  styleUrls: ['./top-players.component.scss'],
  animations: [
    trigger('fadeInOut', [fadeInAnimation('fadeIn => fadeOut', '1s')])
  ]
})
export class TopPlayersComponent implements OnInit {
  @Input()
  public config: IPlayerInstance[];
  @Input()
  public viewMoreTopPlayer: PlayerInstance[];

  @Output()
  public viewMore: EventEmitter<string> = new EventEmitter<string>();

  public mostPointsScored = 0;
  public topPlayersTranslation: string;
  public topPlayersSubHeadingTranslation: string;
  public contentNavBarConfig: IContentNavBarConfig;

  private _playersService: PlayersService;
  private _animationsHelper: AnimationsHelper;
  private _router: Router;

  constructor(playersService: PlayersService, router: Router) {
    this._playersService = playersService;
    this._router = router;
  }

  public ngOnInit() {
    this._setContentNavBarConfig(
      {
        heading: 'topPlayers.byPosition',
        subHeading: 'topPlayers.byPositionSubHeading',
        showSearch: false // REFACTOR: set to true to show search
      }
    );
  }

  public getPlayerPosition(player: PlayerInstance): string {
    return this._playersService.getPlayerPosition(player).name.toLowerCase();
  }

  public viewMorePlayers = (player: PlayerInstance): void => {
    let value = '';

    if (player.points === this.mostPointsScored) {
      value = 'Most points scored';
    } else {
      value = this.getPlayerPosition(player);
    }
    this.viewMore.emit(value);

    this._setContentNavBarConfig(
      {
        heading: `topPlayers.${value.toLowerCase() === 'most points scored' ? 'mostPointsScored' : value}`,
        subHeading: `topPlayers.${value.toLowerCase() === 'most points scored' ? 'mostPointsScoredSubHeading' : value + 'SubHeading'}`,
        actionConfig: {
          text: 'actions.back',
          action: (): void => {
            this.onBackAction();
          }
        }
      }
    );
  }

  public onBackAction(): void {
    this.viewMore.emit('');

    this._setContentNavBarConfig({
      heading: 'topPlayers.byPosition',
      subHeading: 'topPlayers.byPositionSubHeading'
    });
  }

  public onCardClick = (player: PlayerInstance): void => {
    this._playersService.playerDetails = player;
    this._router.navigateByUrl('/players/player/details').then(
      (success: boolean): void => {
      },
      (): void => {

      }
    );
  }

  private _setTopPlayersTranslation(key: {heading: string, subHeading: string}): void {
    this.topPlayersTranslation = key.heading;
    this.topPlayersSubHeadingTranslation = key.subHeading;
  }

  private _setContentNavBarConfig(config: IContentNavBarConfig): void {
    this.contentNavBarConfig = config;
  }
}
