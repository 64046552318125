<fplh-logo-loading-indicator *ngIf="isLoading else isNotLoading"></fplh-logo-loading-indicator>
<ng-template #isNotLoading>
  <div class="Home-mainContentContainer" *ngIf="!isError">
    <fplh-players-stats [config]="playerStatsData"></fplh-players-stats>
    <fplh-pane [config]="paneConfig"></fplh-pane>
  </div>
  <div class="Home-errorMessageContainer" *ngIf="isError">
    <div class="Home-errorMessage">
      <h1 class="Home-errorMessageHeading">{{messageConfig.heading}}</h1>
      <p class="Home-errorMessageSubHeading">{{messageConfig.subHeading}}</p>
    </div>
  </div>
</ng-template>
