import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableComponent } from './presentation-components/table/table.component';
import {TranslationModule} from '../translation/translation.module';
import {IconComponent} from './presentation-components/icon/icon.component';
import { ParseSafeHtmlPipe } from './pipes/parse-safe-html/parse-safe-html.pipe';
import { LoadingSpinnerComponent } from './presentation-components/loading-spinner/loading-spinner.component';
import { LogoLoadingIndicatorComponent } from './presentation-components/logo-loading-indicator/logo-loading-indicator.component';
import { ContentNavBarComponent } from './presentation-components/content-nav-bar/content-nav-bar.component';
import { CardComponent } from './presentation-components/card/card.component';
import { TabbedListComponent } from './presentation-components/tabbed-list/tabbed-list.component';
import {
  TabbedListNavigationComponent
} from './presentation-components/tabbed-list/tabbed-list-navigation/tabbed-list-navigation.component';
import {FormsModule} from '@angular/forms';
import {CoreModule} from '../core/core.module';
import {ImageComponent} from './presentation-components/image/image.component';
import {ImageService} from './services/image/image.service';



@NgModule({
  declarations: [
    ContentNavBarComponent,
    IconComponent,
    ImageComponent,
    LoadingSpinnerComponent,
    LogoLoadingIndicatorComponent,
    ParseSafeHtmlPipe,
    TableComponent,
    CardComponent,
    TabbedListComponent,
    TabbedListNavigationComponent,
  ],
  exports: [
    CardComponent,
    ContentNavBarComponent,
    IconComponent,
    ImageComponent,
    LoadingSpinnerComponent,
    LogoLoadingIndicatorComponent,
    ParseSafeHtmlPipe,
    TableComponent,
    TabbedListComponent,
    TabbedListNavigationComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    TranslationModule,
    FormsModule,
  ],
  providers: [
    ImageService
  ]
})
export class UxModule { }
