import { Injectable } from '@angular/core';
import { CoreModel } from '../../../core/model/core.model';
import {Observable, of, throwError} from 'rxjs';
import moment from 'moment';
import { ILogin } from '../../interfaces/auth.interface';
import { catchError, map} from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import {IAuthResult} from './auth.service.interface';

@Injectable()
export class AuthService {
  private _model: CoreModel;
  private _jwtHelper = new JwtHelperService(); // REFACTOR: my be not use an entire library to decode?

  constructor(model: CoreModel) {
    this._model = model;
  }

  public login(model: ILogin): Observable<any> {
    const payLoad = {
      username: model.username,
      password: model.password
    };

    return this._model.post('users/login', payLoad).pipe(
      map(response => {
        this.setSession(response);

        return true;
      }),
      catchError(error => {
        return throwError('An error has occurred, unable to login');
      })
    );
  }

  private setSession(authResult: IAuthResult): void {
    const token = authResult.data.user.token;
    const exp = this._jwtHelper.getTokenExpirationDate(token).getTime();

    localStorage.setItem('id_token', token);
    localStorage.setItem('expires_at', JSON.stringify(exp) );
  }

  logout() {
    // REFACTOR: Implement error handling
    return this._model.post('users/logout', {}, true).pipe(map(response => {
      localStorage.removeItem('id_token');
      localStorage.removeItem('expires_at');
      return true;
    }));
  }

  public isLoggedIn() {
    const now = new Date().getTime();
    const exp = this.getExpiration();

    return moment(now).isBefore(exp);
  }

  private isLoggedOut() {
    return !this.isLoggedIn();
  }

  private getExpiration() {
    const expiration = JSON.parse(localStorage.getItem('expires_at'));

    return moment(expiration);
  }
}
