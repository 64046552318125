import { Routes } from '@angular/router';
import { PlayerComponent } from '../../../stats/presentation-components/player/player.component';
import { ListsComponent } from '../../../stats/business-components/lists/lists.component';
import {ClubComponent} from '../../../stats/business-components/club/club.component';
import {SearchComponent} from '../../../stats/business-components/search/search.component';

export const statsRoutes: Routes = [
  {
    path: 'players/player/details',
    component: PlayerComponent
  },
  {
    path: 'players/lists',
    component: ListsComponent
  },
  {
    path: 'club/:id',
    component: ClubComponent
  },
  {
    path: 'search',
    component: SearchComponent
  }
];
