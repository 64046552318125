<div class="TabbedList-container" *ngIf="config.list && config.list.length">
    <div class="TabbedList-player" *ngFor="let player of config.list">
        <button class="TabbedList-playerAction"
                (click)="navigateTo(player)">
            <div class="TabbedList-playerImageContainer">
              <fplh-image class="TabbedList-playerImage" [config]="{src: player.getImage(), rounded: true}"></fplh-image>
            </div>
            <span class="TabbedList-playerName">{{player.displayName}}</span>
            <span class="TabbedList-playerCost">{{player.cost | currency:'GBP' :'symbol':'1.0'}}m</span>
            <span class="TabbedList-playerClub">{{player.club.name}}</span>
            <span class="TabbedList-playerPoints">{{this.config.isGameWeek ? player.gameWeekPoints : player.points}} Points</span>
        </button>
    </div>
</div>
