<div class="Player-container">
  <div class="Player-innerContainer" [class.Player--compareContainer]="isComparing">
    <div class="Breadcrumb-container">
      <div class="Breadcrumb-breadcrumbs">
        <div class="Breadcrumb-breadcrumb"
             *ngFor="let breadCrumb of breadCrumbs; let last = last">
          <button class="Breadcrumb-button"
                  [class.Breadcrumb--last]="last"
                  (click)="breadCrumb.onBreadCrumbClick()">{{breadCrumb.text}}</button>
          <span *ngIf="!last" class="Breadcrumb-chevron">></span>
        </div>

      </div>
    </div>
    <div class="Player-compareFormContainer">
      <form class="Player-compareForm" >
        <div class="Player-inputContainer">
          <fplh-loading-spinner
            *ngIf="isLoading"
            class="Player-spinner"
            [colorScheme]="'colorGreyLight'"
            [size]="'small'"></fplh-loading-spinner>
          <input #inputElement class="Player-compareInput"
                 placeholder="Compare with"
                 type="text"
                 id="compareSearch"
                 name="searchWord"
                 (ngModelChange)="onCompare($event)"
                 [ngModel]="compareModel"
                 autocomplete="off"
                 required/>
          <button *ngIf="showClear" class="Player-clearFormButton" (click)="onClearingComparison(inputElement)">x</button>
        </div>
      </form>
      <div class="Player-compareDropdownList" *ngIf="showDll">
        <div class="Player-compareDDLItemContainer">
          <button class="Player-compareItem"
                  *ngFor="let item of ddl"
                  (click)="item.onSelect(item.payload)">{{item.name}}</button>
        </div>
      </div>
    </div>
    <div class="Player-naturalLanguageActionContainer">
      <button *ngIf="isComparing" class="Player-naturalLanguageCloseInfo" (click)="onShowNaturalLanguage()">i</button>
    </div>
    <div class="Player-naturalLanguageContainer" *ngIf="showNaturalLanguageInfo">
      <div class="Player-naturalLanguage">
        <div class="Player-naturalLanguageCloseInfoContainer">
          <button class="Player-naturalLanguageCloseInfo" (click)="onHideNaturalLanguageInfo()">x</button>
        </div>
        <h2 class="Player-naturalLanguageHeading">Summary</h2>
        <span class="Player-naturalLanguageItem" *ngFor="let lang of naturalLanguageAssist">{{lang}}</span>
      </div>
    </div>
    <div class="Player-infoContainer"
         *ngFor="let item of compareItems"
         [class.Player--compare]="isComparing"
         [class.Player-comparer]="item.isToBeCompared">
      <div class="Player-nameImageContainer">
        <div class="Player-imageContainer" [class.isCompareRow]="isComparing">
          <fplh-image [config]="{src: item.player.getImage()}"></fplh-image>
          <div *ngIf="!isComparing"
               [class]="'Player-pointsCircle' + setPlayerColorScheme(item.player.position.name)">
            <div class="Player-pointsAndLabelContainer">
              <h1 class="Player-points">{{item.player.points}}</h1>
              <span class="Player-pointsLabel">Points</span>
            </div>
          </div>
        </div>
        <div class="Player-firstLastNameContainer" *ngIf="!isComparing">
          <h1 class="Player-firstName">{{player.firstName}}</h1>
          <span class="Player-lastName">{{player.lastName}}</span>
        </div>
      </div>
      <div class="Player-statsGrid" [class.isCompareRow]="isComparing">
        <div [class]="'Player-gridHeader ' + setPlayerColorScheme(item.player.position.name, true)">
          <h1 class="Player-gridHeading" *ngIf="!isComparing">{{item.player.position.name}}</h1>
          <h1 class="Player-gridHeading"
              *ngIf="isComparing"
              [class.Player--isComparingHeading]="comparisonPlayer">{{item.player.getFullDisplayName()}}</h1>
        </div>
        <h2 class="Player-clubName Player--greyBg">{{item.player.club.name}}</h2>
        <div class="Player-gridRow"
             *ngFor="let it of item.gridItems; let even = even;"
             [class.Player--greyBg]="!even">
          <span class="Player-gridRowLabel">{{it.label}}</span>
          <span class="Player-gridRowValue" [class.isCompareRow]="isComparing">{{it.value}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
