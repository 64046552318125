import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './business-components/home/home.component';
import {CoreModule} from '../core/core.module';
import {TranslationModule} from '../translation/translation.module';
import {UxModule} from '../ux/ux.module';
import {LanguageSelectorComponent} from './business-components/language-selector/language-selector.component';
import {StatsModule} from '../stats/stats.module';
import {AnimationsModule} from '../animations/animations.module';
import {LeftPaneComponent} from './business-components/pane/left-pane/left-pane.component';
import {RightPaneComponent} from './business-components/pane/right-pane/right-pane.component';
import {PaneComponent} from './business-components/pane/pane.component';
import {SharedModule} from '../shared/shared.module';



@NgModule({
  declarations: [
    HomeComponent,
    LanguageSelectorComponent,
    LeftPaneComponent,
    PaneComponent,
    RightPaneComponent
  ],
  exports: [
    HomeComponent,
    LanguageSelectorComponent,
  ],
  imports: [
    AnimationsModule,
    CommonModule,
    CoreModule,
    UxModule,
    TranslationModule,
    StatsModule,
    SharedModule
  ],
  providers: []
})
export class HomeModule { }
