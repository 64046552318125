import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {
  IModelInstance,
  IPlayersList
} from '../../../core/model-instance/interfaces/model-instance.interfaces';
import { IPaneConfig } from '../pane/interfaces/pane.interface';
import { CoreModel } from '../../../core/model/core.model';
import {IApiDataResponse} from '../../../core/api/core.api.interface';
import { IHomeResponse } from './home.component.interface';
import {ICoreModelOptions} from '../../../core/model/interfaces/core.model.interface';
import {CoreModelPersistenceKeys} from '../../../core/model/enums/CoreModelPersistenceKeys.enum';
import {NavigationManagementService} from '../../../shared/services/navigation-management/navigation-management.service';
import {FooterService} from '../../../shared/services/footer/footer.service';

@Component({
  selector: 'fplh-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  public paneConfig: IPaneConfig;
  public isLoading = true;
  public playerStatsData: any;
  public messageConfig = {
    heading: '',
    subHeading: ''
  };
  public isError = false;


  private _model: CoreModel;
  private _nms: NavigationManagementService;
  private _fs: FooterService;

  constructor(model: CoreModel, nms: NavigationManagementService, fs: FooterService) {
    this._model = model;
    this._nms = nms;
    this._fs = fs;
    // sessionStorage.clear();
  }

  public ngOnInit() {
    this._getPlayers();
    // this._getTotalForClubs();
  }

  public ngOnDestroy() {
    this._fs.resetPosition();
  }

  public ngAfterViewInit() {}

  private _getPlayers(): void {
    const persistOptions: ICoreModelOptions = {
      persistence: {
        persist: true,
        key: CoreModelPersistenceKeys.HOME_DATA
      }
    };

    this._model.get<IApiDataResponse<IHomeResponse>>(
      'Players/home',
      {},
      persistOptions
    ).subscribe(
      (result: IModelInstance<IApiDataResponse<IHomeResponse>>): void => {
        this._onSubscribe(result);
        this.isLoading = false;
        this._fs.resetPosition();
      },
      (errorResponse: {message: string; exceptionMessage: string}): void => {
        const message = errorResponse.message;
        const messageSplit = message?.split(':') || '';

        this.messageConfig.heading = 'An error occurred';
        this.messageConfig.subHeading = 'Please try again later';

        if (messageSplit && messageSplit.length) {
          this.messageConfig.heading = messageSplit[0];
          this.messageConfig.subHeading = messageSplit[1] + ', please try again later';
        }

        this._nms.navigationConfigSubject$.next({
          disableAllNavigation: true
        });
        this.isError = true;
        this.isLoading = false;
        this._fs.positionBottom();
      }
    );
  }

  private _getTotalForClubs(): void {
    this._model.get('Clubs/totals', {}).subscribe(
      (response: any): void => {
      },
      (e: any): void => {
      }
    );
  }

  private _onSubscribe(response: IModelInstance<IApiDataResponse<IHomeResponse>>): void {
    // Because we need to also filter from the top ten list to get top five players from each position
    this.playerStatsData = response;
    this.paneConfig = {
      leftPane: response,
      rightPane: {
        topStats: response.getIndividualStats(),
        clubTotals: response.getClubTotals()
      }
    };
  }

}
