import { Component, OnInit } from '@angular/core';
import { PlayersService } from '../../services/players/players.service';
import { Router } from '@angular/router';
import { CoreModel } from '../../../core/model/core.model';
import { PlayerInstance } from '../../player-instance/player-instance';
import { IPlayerInstance } from '../../player-instance/interfaces/player-instance.interface';
import Timer = NodeJS.Timer;

@Component({
  selector: 'fplh-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit {
  public player: IPlayerInstance;
  public breadCrumbs: IBreadcrumbItem[];
  public compareModel: any = '';
  public compareResult: IPlayerInstance[];
  public ddl: any[];
  public comparisonPlayer: IPlayerInstance;
  public showDll = false;
  public showClear = false;
  public isComparing = false;
  public naturalLanguageAssist: string[] = [];
  public gridHeaderItems: {label: string; value: string}[];
  public compareItems: ICompareItem[] = [];
  public showNaturalLanguageInfo = false;
  public isLoading = false;

  private _playersService: PlayersService;
  private _router: Router;
  private _model: CoreModel;
  private _setTimer: Timer;
  private _term = '';

  constructor(playersService: PlayersService, router: Router, model: CoreModel) {
    this._playersService = playersService;
    this._router = router;
    this._model = model;
  }

  public ngOnInit() {
    this.breadCrumbs = [
      {
        text: 'Home',
        onBreadCrumbClick: (): void => {
          this._router.navigateByUrl('/');
        }
      },
      {
        text: 'Player details',
        onBreadCrumbClick: (): void => {
        }
      }
    ];
    if (this._playersService.playerDetails) {
      // Here we set session storage to retrieve player details via json
      sessionStorage.setItem('player-details', JSON.stringify(this._playersService.playerDetails));
      const player: IPlayerInstance = this._playersService.playerDetails;
      this.player = player;
      this.compareItems.push({isToBeCompared: false, player, gridItems: this._setUpGridHeaderConfig(this.player)});
    } else {
      const playerDetails: PlayerInstance = JSON.parse(sessionStorage.getItem('player-details'));
      // const player: IPlayerInstance = this._playersService.assignPlayerInstance(playerDetails);
      const player = null;

      if (player) {
        this.player = player;
        this.compareItems.push({isToBeCompared: false, player, gridItems: this._setUpGridHeaderConfig(this.player)});
      } else {
        this._router.navigateByUrl('/');
      }
    }
  }

  public onCompare(term: string): void {
    this.isLoading = true;
    this._term = term;
    if (term === '') {
      this.showDll = false;
      this.compareResult = [];
      this.showClear = false;

      if (this.compareItems.length > 1) {
        this.compareItems.pop();
      }
    }

    this._setTimer = setTimeout((): void => {

      if (term !== '') {
        const position = this.player.getPosition().toLowerCase();

        this._model.post(
          `players/player/Compare?compare=${term}&position=${position}&playerId=${this.player.id}`, {}
        ).subscribe((response: IPlayerInstance[]): void => {
          const hasValue = !!this._term;

          if (hasValue && response && response.length) {
            this.compareResult = response;
            this.showDll = true;
            this.showClear = true;
            this._populateDropdownList();
            this.isLoading = false;
          } else {
            this.showDll = false;
            this.compareResult = [];
            this.showClear = false;
            this.isLoading = false;
          }
        });
      }
    }, 500);
  }

  public onImageError(player: PlayerInstance): void {
    player.image = 'placeholder.png';
  }

  public onClearingComparison(input: HTMLInputElement): void {
    input.value = '';
    this.isComparing = false;
    this.showDll = false;
    this.showClear = false;
    this.compareResult = [];
    this.naturalLanguageAssist = [];

    if (this.compareItems.length > 1) {
      this.compareItems.pop();
    }

    if (this._setTimer) {
      clearInterval(this._setTimer);
    }

  }

  private _setUpGridHeaderConfig(player: IPlayerInstance): {label: string; value: string}[] {
    this.gridHeaderItems = [
      {
        label: 'Assists',
        value: player.assists.toString()
      },
      {
        label: 'Cost',
        value: `£${player.cost}m`
      },
      {
        label: 'Form',
        value: player.form.toString()
      },
      {
        label: 'Game Week Points',
        value: player.gameWeekPoints.toString()
      },
      {
        label: 'Goals',
        value: player.goalsScored.toString()
      },
      {
        label: 'Creativity',
        value: player.creativity.toString(),
      }
    ];

    if (this.isComparing) {
      this.gridHeaderItems.unshift(
        {
          label: 'Points',
          value: player.points.toString(),
        }
      );
    }

    return this.gridHeaderItems;
  }

  public setPlayerColorScheme(positionName: string, isGrid = false): string {
    const posName: string = positionName === 'Goalkeeper' ? 'goalKeeper' : positionName.toLowerCase();

    return ` Player--${!isGrid ? posName + 'Colors' : posName }`;
  }

  public onShowNaturalLanguage() {
    this.showNaturalLanguageInfo = true;
  }

  public onHideNaturalLanguageInfo(): void {
    this.showNaturalLanguageInfo = false;
  }

  private _populateDropdownList() {
    if (this.compareResult && !!this.compareResult.length) {
      this.ddl = this.compareResult.map((p: IPlayerInstance): any => {
        return {
          name: `${p.firstName} ${p.lastName}`,
          payload: p,
          onSelect: (player: any): void => {
            this.isComparing = true;
            this.comparisonPlayer = this._playersService.assignPlayerInstance(player);
            this.compareItems[0].gridItems = this._setUpGridHeaderConfig(this.player);
            this.compareItems.push(
              {
                isToBeCompared: true,
                player: this.comparisonPlayer,
                gridItems: this._setUpGridHeaderConfig(this.comparisonPlayer)
              }
            );
            this.showDll = false;
            this.showClear = true;
            this._calculateStatsDiff();
          }
        };
      });
    }
  }

  private _calculateStatsDiff() {
    const diff = Number((this.player.assists - this.comparisonPlayer.assists).toFixed(1));
    const diffCost = Number((this.player.cost - this.comparisonPlayer.cost).toFixed(1));
    const diffGw = Number((this.player.gameWeekPoints - this.comparisonPlayer.gameWeekPoints).toFixed(1));
    const diffCreativity = Number((this.player.creativity - this.comparisonPlayer.creativity).toFixed(1));
    const diffGoals = Number((this.player.goalsScored - this.comparisonPlayer.goalsScored).toFixed(1));

    this.naturalLanguageAssist = [
      `${this._getPlural(diff, 'assists', this.player.displayName , this.comparisonPlayer.displayName)}`,
      `${this._getPlural(diffCost, 'cost', this.player.displayName , this.comparisonPlayer.displayName)}`,
      `${this._getPlural(diffGw, 'gw', this.player.displayName , this.comparisonPlayer.displayName)}`,
      `${this._getPlural(diffCreativity, 'creativity', this.player.displayName , this.comparisonPlayer.displayName)}`,
      `${this._getPlural(diffGoals, 'goals', this.player.displayName , this.comparisonPlayer.displayName)}`
    ];
  }

  private _getPlural(score: number, prop: string, plName: string, compName: string) {
    const isLess = Math.sign(score) === -1;

    if (!isLess && prop === 'assists' && score !== 0) {
      return `${isLess ? compName : plName } has ${score} more  assists than ${isLess ? plName : compName }`;
    }

    if (!isLess && prop === 'cost') {
      return `${!isLess ? compName : plName } costs £${score.toString().replace('-', '')}m less than ${isLess ? compName : plName }`;
    }

    if (isLess && prop === 'cost') {
      return `${isLess ? plName : compName } costs £${score.toString().replace('-', '')}m less than ${isLess ? compName : plName }`;
    }

    if (isLess && prop === 'gw') {
      return '';
    } else if (!isLess && prop === 'gw') {
      const prefix = `${!isLess ? plName : compName } scored ${score.toString().replace('-', '')}`;

      return `${prefix} more game week points than ${!isLess ? compName : plName }`;
    }

    if (!isLess && prop === 'creativity') {
      return `${!isLess ? plName : compName } is more creative than ${!isLess ? compName : plName }`;
    }

    if (isLess && prop === 'creativity') {
      return `${isLess ? compName : plName } is more creative than ${isLess ? plName : compName }`;
    }

    if (!isLess && prop === 'goals') {
      return `${isLess ? compName : plName } has scored ${score} more goals than ${isLess ? plName : compName }`;
    } else {
      return '';
    }
  }
}

export interface IBreadcrumbItem {
  text: string;
  onBreadCrumbClick: () => void;
}

export interface ICompareItem {
  isToBeCompared: boolean;
  player: IPlayerInstance;
  gridItems: { label: string; value: string }[];
}
