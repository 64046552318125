import {Component, Input, OnInit} from '@angular/core';
import {IContentNavBarConfig} from './interfaces/content-nav-bar.interface';
import {CoreModel} from '../../../core/model/core.model';
import Timer = NodeJS.Timer;
import {PlayersService} from '../../../stats/services/players/players.service';

@Component({
  selector: 'fplh-content-nav-bar',
  templateUrl: './content-nav-bar.component.html',
  styleUrls: ['./content-nav-bar.component.scss']
})
export class ContentNavBarComponent implements OnInit {
  @Input()
  public config: IContentNavBarConfig;

  public showClear = false;
  public showSearch = false;

  public searchModel: any;
  private _term = '';
  private _model: CoreModel;
  private _payload: any = {};
  private _setTimer: Timer;
  private _playersService: PlayersService;

  constructor(model: CoreModel, playersService: PlayersService) {
    this._model = model;
    this._playersService = playersService;

    this._payload = {
      nl: '',
      positionKeyword: 'forwar',
      propertyKeyword: 'points',
      min: 100,
      max: 150
    };
  }

  public ngOnInit() {
    this.showSearch = this.config.showSearch;
  }

  public onSearch(term: any): void {
    this._term = term;

    if (this._term !== '') {
      this._setTimer =  setTimeout((): void => {
        this._model.post('Search/nl', this._payload).subscribe((response: any[]): void => {
          if (response && response.length) {
            const playerInstances = response.map((res): any => {
              return this._playersService.assignPlayerInstance(res);
            });
          }
        });
      }, 5000);
    } else {
      if (this._setTimer) {
        clearInterval(this._setTimer);
      }
    }
  }

  public onClearingComparison(input: any): void {
    if (this._setTimer) {
      clearInterval(this._setTimer);
    }
  }

}
