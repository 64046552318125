import { Pipe, PipeTransform } from '@angular/core';
import {I18NextPipe} from 'angular-i18next';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {
  private _i18NextPipe: I18NextPipe;

  constructor(i18NextPipe: I18NextPipe) {
    this._i18NextPipe = i18NextPipe;
  }

  public transform(translateId: string, ...args: any[]): string {
    return this._i18NextPipe.transform(translateId);
  }

}
