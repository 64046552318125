import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {CoreModule} from './modules/core/core.module';
import {HomeModule} from './modules/home/home.module';
import {TranslationModule} from './modules/translation/translation.module';
import {UxModule} from './modules/ux/ux.module';
import {AnimationsModule} from './modules/animations/animations.module';
import {RoutingModule} from './modules/routing/routing.module';
import {StatsModule} from './modules/stats/stats.module';
import {AdminModule} from './modules/admin/admin.module';
import {AuthModule} from './modules/auth/auth.module';
import {Location} from '@angular/common';
import {SharedModule} from './modules/shared/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptorService} from './modules/auth/services/interceptor/auth-interceptor.service';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
      AdminModule,
      AnimationsModule,
      AuthModule,
      BrowserModule,
      BrowserAnimationsModule,
      CoreModule,
      HomeModule,
      StatsModule,
      RoutingModule,
      SharedModule,
      TranslationModule,
      UxModule
    ],
    providers: [
      Location,
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptorService,
        multi: true
      }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
