import fallbackLng from './fallback-language';

const setTranslationOptions = (config: {lng: string, fallbackLng: string, ns: any}): any => {
  const fallbackLanguage: any = JSON.parse(sessionStorage.getItem('fallbackLng')) || {};

  if (!fallbackLanguage.default) {
    fallbackLanguage.default = 'en';
  }

  return {
    whitelist: ['en', 'lu', 'ge'],
    fallbackLng: fallbackLanguage.default,
    returnEmptyString: false,
    backend: {
      loadPath: 'assets/translations/locales/{{lng}}.translation.json'
    },
    ns: ['translation'],
  };
};

export default setTranslationOptions;
