<div class="ContentNavBar-container">
    <div class="ContentNavBar-headingsActionContainer">
      <div class="ContentNavBar-actionContainer"
           *ngIf="config && config.actionConfig">
        <button class="ContentNavBar-action" (click)="config.actionConfig.action()">
          <fplh-icon class="ContentNavBar-actionIcon" [config]="{name: 'back'}"></fplh-icon>
          <span class="ContentNavBar-actionText">{{'actions.back' | translate}}</span>
        </button>
      </div>
      <div class="ContentNavBar-headingsContainer" [class.hasAction]="config && config.actionConfig">
        <div class="ContentNavBar-headingContainer">
          <h1 class="ContentNavBar-heading">{{config && config.heading | translate}}</h1>
        </div>
        <div class="ContentNavBar-subHeadingContainer">
          <p class="ContentNavBar-subHeading">{{config && config.subHeading | translate}}</p>
        </div>
      </div>
    </div>
</div>
