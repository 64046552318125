import {animate, animation, AnimationReferenceMetadata, style, transition, useAnimation} from '@angular/animations';

const fadeInAnimation = (stateChanger: string, duration: string): any => {
  const animationInstance: AnimationReferenceMetadata = animation(
    [
      style({opacity: 0}),
      animate(`${duration}`)
    ]
  );

  return transition(stateChanger, [useAnimation(animationInstance)]);
};

export default fadeInAnimation;
