import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdatePlayersComponent } from './business-components/update-players/update-players.component';
import { CoreModule } from '../core/core.module';
import { AuthModule } from '../auth/auth.module';
import { FormsModule } from '@angular/forms';
import { UxModule } from '../ux/ux.module';



@NgModule({
  declarations: [
    UpdatePlayersComponent
  ],
  exports: [
    UpdatePlayersComponent
  ],
    imports: [
        AuthModule,
        CommonModule,
        CoreModule,
        FormsModule,
      UxModule
    ]
})
export class AdminModule { }
