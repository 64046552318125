import {IModelInstance, IPlayersList, ITopTenPlayersByPosition} from './interfaces/model-instance.interfaces';
import {
  IIndividualStats,
  IIndividualStatsResponse,
  IPlayer,
  IPlayerInstance
} from '../../stats/player-instance/interfaces/player-instance.interface';
import {PlayerInstance} from '../../stats/player-instance/player-instance';
import {IHomeResponse} from '../../home/business-components/home/home.component.interface';
import {IClubTotal} from '../../shared/interfaces/shared.interface';

export class ModelInstance<T> implements IModelInstance<T> {
  public data: T;

  public getTopFiftyPlayers(): IPlayerInstance[] {
    return this._setPlayerInstance('topFifty');
  }

  public getTopTenPlayers(): ITopTenPlayersByPosition {
    if ('topTen' in this.data) {
      const positionData: ITopTenPlayersByPosition = (this.data as unknown as IHomeResponse).topTen.byPosition;

      return {
        goalkeepers: this._assignPlayerInstance(positionData.goalkeepers),
        defenders: this._assignPlayerInstance(positionData.defenders),
        midfielders: this._assignPlayerInstance(positionData.midfielders),
        forwards: this._assignPlayerInstance(positionData.forwards),
      };
    }

    return {} as unknown as any;
  }

  public getTopFivePlayers(): IPlayerInstance[] {
    if ('topFive' in this.data) {
      // ToDo - sort this out
      const topFiveData: IPlayer[] = (this.data as unknown as IHomeResponse).topFive;
      const topFivePlayers = this._assignPlayerInstance(topFiveData);

      return topFivePlayers;
    }

    return [];
  }

  public getMostPoints(): IPlayerInstance[] {
    if ('topTen' in this.data) {
      const mostPointsData: IPlayer[] = (this.data as unknown as IHomeResponse).topTen.mostPoints;

      return this._assignPlayerInstance(mostPointsData);
    }

    return [];
  }

  public getMostGameWeekPoints(): IPlayerInstance[] {
    if ('topTen' in this.data) {
      // ToDo - sort this out
      const topTenData: IPlayer[] = (this.data as unknown as IHomeResponse).topTen.mostGameWeekPoints;

      return this._assignPlayerInstance(topTenData);
    }

    return [];
  }

  public getIndividualStats(): IIndividualStats {
    if ('topTen' in this.data) {
      // ToDo - sort this out
      const individualStats: IIndividualStatsResponse = (this.data as unknown as IHomeResponse).topTen.individualStats;

      return {
        assists: this._singlePlayerInstance(individualStats.assists),
        mostCleanSheetsDEF: this._singlePlayerInstance(individualStats.mostCleanSheetsDEF),
        mostCleanSheetsGK: this._singlePlayerInstance(individualStats.mostCleanSheetsGK),
        mostGoals: this._singlePlayerInstance(individualStats.mostGoals),
        inform: this._singlePlayerInstance(individualStats.inform),
      };
    }

    return {} as unknown as any;
  }

  public getClubTotals(): IClubTotal[] {
    if ('clubTotals' in this.data) {
      return (this.data as unknown as IHomeResponse).clubTotals;
    }

    return [];
  }

  private _singlePlayerInstance(player: IPlayer): IPlayerInstance {
    return Object.assign(new PlayerInstance(), player);
  }

  private _setPlayerInstance(prop: string): IPlayerInstance[]  {
    const data: IPlayersList = this.data as unknown as IPlayersList; // ToDo - refactor and change this fo

    if (data && data[prop] && data[prop].length) {

      if (data[prop].constructor.name === 'Array') {
        const playerInstances: IPlayerInstance[] = this._assignPlayerInstance(data[prop]);

        return playerInstances;
      }

      return [];
    }

    return [];
  }

  private _assignPlayerInstance(collection: IPlayer[]): IPlayerInstance[] {
    return collection && collection.map<IPlayerInstance>((player: IPlayer): IPlayerInstance => {
      return Object.assign(new PlayerInstance(), player);
    });
  }
}
