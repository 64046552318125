<div class="Search-container">
  <div class="Search-loadingBackdrop" *ngIf="isLoading">
    <fplh-loading-spinner
      class="Search-spinner"
      [colorScheme]="'colorGreyLight'"></fplh-loading-spinner>
  </div>
  <div class="Search-formContainer" [class.Search--centrePosition]="centrePosition">
    <form class="Search-form" (submit)="search(inputElem)">
      <div class="Search-inputContainer">
        <input class="Search-input" type="text"
               #inputElem
               [formControl]="searchControl"
               (change)="change($event)"
               (keydown.enter)="onEnter($event, inputElem)"
               placeholder="Get players 100 points or more..." autofocus>
        <button #clearButton class="Search-action Search-clearValueAction" (click)="clearInput()" *ngIf="hasValue">
          <fplh-icon [config]="{name: 'close', size: 'medium'}"></fplh-icon>
        </button>
        <button #searchButton class="Search-action" (click)="search(inputElem)">
          <fplh-icon [config]="{name: 'search', size: 'medium'}"></fplh-icon>
        </button>
      </div>
    </form>
  </div>
  <div class="Search-resultsContainer" *ngIf="showResults">
    <h1 *ngIf="message" class="Search-resultsMessage">{{message}}</h1>
    <fplh-player-card *ngFor="let p of result.data" [config]="{ player: p, actions: { onCardClick: onCardClick }}"></fplh-player-card>
  </div>
</div>
