import {ICollectionInstance} from './collection-instance.interface';

export class CollectionInstance implements ICollectionInstance {
  public items: any[];
  public count: number;

  constructor(collection: any[]) {
    if (!!collection.length) {
      this.items = collection;
      this.count = this.items.length;
    } else {
      this.items = [];
      this.count = 0;
    }
  }

  public getFirstItem(): any {
    return this.items[0];
  }

  public getLastItem(): any {
    return this.items[this.items.length - 1];
  }

  public getItemByKeyValue(kvp: string): any {
    const kvpSplit: string[] = kvp.split(':');
    const key: string = kvpSplit[0];
    const value: string = kvpSplit[1];
  }
}
