<div class="LogoLoadingIndicator-backDrop">
    <div class="LogoLoadingIndicator-container">
        <div class="LogoLoadingIndicator-icon">
            <fplh-icon [config]="{name: 'premier-league-lion', size: 'loadingIndicator'}"></fplh-icon>
        </div>
        <div class="LogoLoadingIndicator-iconText">
            <h1 class="LogoLoadingIndicator-iconHeading">FPLH</h1>
            <span class="LogoLoadingIndicator-iconSubText">Fantasy Premier League Helper</span>
        </div>
        <div class="LogoLoadingIndicator-loadingSpinner">
            <fplh-loading-spinner [colorScheme]="'colorGreyLight'"></fplh-loading-spinner>
        </div>
    </div>
</div>
