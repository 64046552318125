import { Component, Input, OnInit } from '@angular/core';
import { IMenuIconSvgConfig } from './menu-icon.interface';

@Component({
  selector: 'fplh-menu-icon-svg',
  templateUrl: './menu-icon-svg.component.html',
  styleUrls: ['./menu-icon-svg.component.scss']
})
export class MenuIconSvgComponent implements OnInit {
  @Input()
  public config: IMenuIconSvgConfig;
  constructor() { }

  public ngOnInit() {}

}
