import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import { CoreModel } from '../../../core/model/core.model';
import { IPlayer, IPlayerInstance } from '../../player-instance/interfaces/player-instance.interface';
import { PlayersService } from '../../services/players/players.service';
import { PlayerInstance } from '../../player-instance/player-instance';
import { Router } from '@angular/router';
import {SearchService} from '../../services/search/search.service';
import {FooterService} from '../../../shared/services/footer/footer.service';

@Component({
  selector: 'fplh-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit , AfterViewInit, OnDestroy {
  public searchControl: FormControl;
  public isLoading = false;
  public centrePosition = true;
  public showResults = false;
  public result = {
    count: 0,
    data: []
  };
  public hasValue = false;
  public message = 'Nothing to show';

  private _cm: CoreModel;
  private _ps: PlayersService;
  private _r: Router;
  private _ss: SearchService;
  private _fs: FooterService;

  private _isOnChange = false;

  constructor(cm: CoreModel, ps: PlayersService, r: Router, ss: SearchService, fs: FooterService) {
    this._cm = cm;
    this._ps = ps;
    this._r = r;
    this._ss = ss;
    this._fs = fs;

    const val = sessionStorage.getItem('QUERY_SEARCH') || '';

    this.hasValue = !!val;

    this.searchControl = new FormControl(val);
  }

  public ngOnInit(): void {
    if (this.searchControl.value) {
      this._performSearch(this.searchControl.value);
    }
  }

  public ngAfterViewInit() {
    this._fs.positionBottom();
  }

  public ngOnDestroy() {
    this._fs.resetPosition();
  }

  public change(ev: any): void {
    // const val: string = ev.target.value || '';
    //
    // this._isOnChange = true;
    // this._performSearch(val);
    // send model to api, receive result then populate page.
  }

  public onEnter(ev: Event, inputElem: HTMLInputElement) {
    ev.preventDefault();
    this.search(inputElem);
  }

  public search(input: HTMLInputElement): void {

    const value = input.value;

    this.searchControl.patchValue(value);
    this.hasValue = !!value;

    sessionStorage.setItem('QUERY_SEARCH', value);

    if (value && !this._isOnChange) {
      this._performSearch(value);
    }
  }

  public clearInput(): void {
    this._fs.positionBottom();
    this.searchControl.patchValue('');
    sessionStorage.removeItem('QUERY_SEARCH');
    this.result.data.length = 0;
    this.message = '';
    this.hasValue = false;
  }

  public onCardClick = (p: PlayerInstance): void => {
    this._ps.playerDetails = p;
    this._r.navigateByUrl('/players/player/details').then(
      (success: boolean): void => {
      },
      (): void => {

      }
    );
  }

  private _performSearch(val: string): void {
    const intentsModel = this._setIntentsModel(val);

    this.isLoading = true;
    this._cm.post('search/query', intentsModel).subscribe(
      (response: any): void => {
        const res: IPlayer[] = response.data.map((p: IPlayer): IPlayerInstance => {
          return this._ps.assignPlayerInstance(p);
        });

        this._isOnChange = false;
        this.result.count = res.length;
        this.result.data = res;

        if (!!res.length) {
          this.message =  `${res.length} players found`;
          this._fs.resetPosition();
        } else {
          this.message =  'Nothing to show';
          this._fs.positionBottom();
        }
        this.showResults = true;
        this.isLoading = false;
        this.centrePosition = false;
      },
      (): void => {
        this.message =  'Nothing to show';
        this.result.data = [];
        this.isLoading = false;
        this.showResults = true;
        this.centrePosition = false;
      }
    );
  }

  private _getKeyWordEquation(value: string): string {

    if (value.includes('more') || value.includes('plus') || value.includes('+') || value.includes('>')) {
      return '+';
    }

    if (value.includes('less') || value.includes('-') || value.includes('<')) {
      return '-';
    }
    return   '';
  }

  private _setAttributeCount(num: any[]) {
    let joined = 0;

    if (num && num.length > 1) {
      joined = Number(num[0]);
    } else if (num && num.length === 1) {
      joined = Number(num.join(''));
    }

    return joined;
  }

  private _setIntentsModel(val: string): any {
    const value: string = val.toLowerCase() || '';
    const intents = this._ss.getIntents(value);
    const model = {
      intent: null,
      intents,
      search: value
    };

    return model;
  }
}
