import { Injectable } from '@angular/core';
import { CoreModel } from '../../../core/model/core.model';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class ImageService {
  private _coreModel: CoreModel;

  constructor(coreModel: CoreModel) {
    this._coreModel = coreModel;
  }

  public getImage(url: string): Observable<any> {
   return this._coreModel.getImage(url).pipe(
      switchMap(response => this.readFile(response))
   );
  }

  private readFile(blob: Blob): Observable<string> {
    return new Observable(obs => {
      const reader = new FileReader();

      reader.onerror = err => obs.error(err);
      reader.onabort = err => obs.error(err);
      reader.onload = () => obs.next(reader.result as string);
      reader.onloadend = () => obs.complete();

      return reader.readAsDataURL(blob);
    });
  }
}
