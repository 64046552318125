import { Api } from '../api/core.api';
import {catchError, map} from 'rxjs/operators';
import {Observable, ObservableInput, Subscriber, throwError} from 'rxjs';
import {ICoreModel, ICoreModelOptions } from './interfaces/core.model.interface';
import {IApiDataResponse, IApiResponse, IPlayersTopListsResponse} from '../api/core.api.interface';
import { CollectionInstance } from '../collection-instance/collection-instance';
import { ModelInstance } from '../model-instance/model-instance';
import { ICollectionInstance } from '../collection-instance/collection-instance.interface';
import { Injectable, Type } from '@angular/core';
import {IAllPlayers, IModelInstance} from '../model-instance/interfaces/model-instance.interfaces';
import {HttpErrorResponse} from '@angular/common/http';

// ToDo - use correct types
@Injectable()
export class CoreModel implements ICoreModel {
  public data: any[];
  public teams: any[];

  private _api: Api;
  public elementTypes: any;

  constructor(api: Api) {
    this._api = api;
  }

  public get<T>(url: string, params?: any, options?: ICoreModelOptions): Observable<IModelInstance<T>> {
    const hasOptions = !!(options);

    if (hasOptions) {
      this._getPersistedData<T>(options);
    }

    return this._getDataFromApi<T>(url, params, options);
  }

  public getAllPlayers(url: string, params?: any, persist: boolean = false): Observable<any> {
    return this._api.get(url, params).pipe(
      map(
        (response: IAllPlayers): any => {
          return response;
        }
      )
    );
  }

  public getList<T>(url: string, params?: any, instanceModel?: Type<T>): Observable<ICollectionInstance> {
    return this._api.get(url, params).pipe(
      map(
        (response: IApiResponse): any => {
          const data: any = response && response.data;

          if (data && data.constructor.name === 'Array' && data.length) {
            const mappedItems: any[] = data.map((item): void => {
              const instance: any = instanceModel ? instanceModel : ModelInstance;

              return Object.assign(new instance(), item);
            });

            return new CollectionInstance(mappedItems);
          }

          return new CollectionInstance([]);
        }
      )
    );
  }

  public post(url: string, payload: any, bearer = false): Observable<any> {
    return this._api.post(url, payload, bearer);
  }

  public getImage(url: string): Observable<any> {
    return this._api.getImage(url);
  }

  public postToForm(url: string, payload: any): Observable<any> {
    return this._api.postToForm(url, payload);
  }

  private _getDataFromApi<T>(url: string, params: any, options): Observable<IModelInstance<T>> {
    const hasOptions = !!(options);

    return this._api.get(url, params).pipe(
      map((response: IApiDataResponse<T>): any => {
        const persist = hasOptions && options.persistence;
        const modelInstance: IModelInstance<T> = Object.assign(new ModelInstance<T>(), response);

        if (persist) {
          sessionStorage.setItem(options.persistence.key, JSON.stringify(response));
        }

        return modelInstance;
      }),
      catchError((caught: HttpErrorResponse,  co: any): ObservableInput<any> => {
        const apiErrorResponse: {data: {message: string, exceptionMessage: string}} = caught.error;
        const data = apiErrorResponse && apiErrorResponse.data;
        const message: string = data && data.message || 'An error has occurred';

        return throwError(data || message);
      })
    );
  }

  private _getPersistedData<T>(options: ICoreModelOptions): Observable<IModelInstance<T>>  {
    // Get the persisted data
    const persistedJsonData = sessionStorage.getItem(options.persistence.key);
    const persisted = JSON.parse(persistedJsonData);

    if (persisted) {
      const persistedModelInstance: IModelInstance<T> = Object.assign(new ModelInstance<T>(), persisted);

      return new Observable<any>(
        (subscriber: Subscriber<IModelInstance<T>>): any => {
          subscriber.next(persistedModelInstance);
        }
      ).pipe(map(
        (res: any): any => {
          return res;
        }
      ));
    }
  }
}
