import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './services/auth/auth.service';
import { HttpClientModule } from '@angular/common/http';
import {CoreModule} from '../core/core.module';
import {RegistrationComponent} from './business-components/registration/registration.component';
import {FormsModule} from '@angular/forms';



@NgModule({
  declarations: [
    RegistrationComponent
  ],
  exports: [
    RegistrationComponent
  ],
  imports: [
    CoreModule,
    CommonModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [
    AuthService
  ]
})
export class AuthModule { }
