<div class="LanguageSelector-container">
  <div class="LanguageSelector-selectedLanguageContainer">
    <button class="LanguageSelector-selectedLanguageAction" (click)="toggleLanguages()">
      <div class="LanguageSelector-iconContainer LanguageSelector--noMargin">
        <fplh-icon [config]="{name: selectedLanguage.icon}"></fplh-icon>
      </div>
    </button>
  </div>
  <div class="LanguageSelector-popup" *ngIf="showPopup">
    <button class="LanguageSelector-supportedLanguage" *ngFor="let language of supportedLanguages" (click)="onLanguageClick(language)">
      <div class="LanguageSelector-iconContainer">
        <fplh-icon [config]="{name: language.icon}"></fplh-icon>
      </div>
      <div class="LanguageSelector-labelContainer">
        <span class="LanguageSelector-label">{{language.label}}</span>
      </div>
    </button>
  </div>
</div>
