import {Component, Input, OnInit} from '@angular/core';
import {CoreModel} from '../../../core/model/core.model';
import {TranslationService} from '../../../translation/services/translation/translation.service';
import {PlayersService} from '../../services/players/players.service';
import {IPlayerInstance} from '../../player-instance/interfaces/player-instance.interface';
import {
  IModelInstance,
  IPlayersList,
  ITopTenPlayersByPosition
} from '../../../core/model-instance/interfaces/model-instance.interfaces';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'fplh-players-stats',
  templateUrl: './players-stats.component.html',
  styleUrls: ['./players-stats.component.scss']
})
export class PlayersStatsComponent implements OnInit {
  @Input()
  public config: IModelInstance<IPlayersList>; // TEMP: subject to change
  public topPlayersConfig: IPlayerInstance[] = [];
  public vieMoreTopPlayers: IPlayerInstance[] = [];
  public showPlayerStats = false;
  public players: IPlayerInstance[];
  public players$: BehaviorSubject<IPlayerInstance[]> = new BehaviorSubject<IPlayerInstance[]>([]);

  private _translationService: TranslationService;
  private _playersService: PlayersService;

  constructor(translationService: TranslationService, playersService: PlayersService) {
    this._translationService = translationService;
    this._playersService = playersService;
  }

  public ngOnInit(): void {
    this._setPlayersData();
  }

  public onViewMorePlayers(category: string): void {
    let moreTopPlayers: IPlayerInstance[] = [];

    if (category) {
      moreTopPlayers = this._setMoreTopPlayers(category);
    } else {
      moreTopPlayers = [];
      this.players$.subscribe((list: IPlayerInstance[]): void => {
        this.players = list;
      });
    }

    this.vieMoreTopPlayers = moreTopPlayers;
  }

  private _setPlayersData(): void {
    const topTen: ITopTenPlayersByPosition = this.config.getTopTenPlayers();
    const topFive: IPlayerInstance[] = this.config.getTopFivePlayers();

    this.players$.next(topFive);
    this.players = topFive;
    this.topPlayersConfig = topFive;
    this._playersService.onInit(topTen);
    this.showPlayerStats = true;
  }

  private _setMoreTopPlayers(category: string): any {
    return this._playersService.getTopPlayersByPosition(
      {
        position: category,
        quantity: 5,
      }
    ) as IPlayerInstance[] ;
  }

}
