<!--The content below is only a placeholder and can be replaced.-->
<div id="uniqueIdForAppContainer" class="App-container">
  <div class="App-navigationContainer" fplhFixedOnScroll>
    <div class="App-headingContainer">
      <fplh-menu class="App-menuComponent"
                 *ngIf="!isActionDisabled"
                 [config]="menuConfig"></fplh-menu>
      <button class="App-logoAction" (click)="goHome()">
        <div class="App-iconContainer">
          <fplh-icon [config]="{name: 'premier-league-lion', size: 'xlarge'}"></fplh-icon>
        </div>
        <div class="App-iconText">
          <h1 class="App-heading">FPLH</h1>
          <span class="App-subHeading">Fantasy Premier League Helper</span>
        </div>
      </button>
<!--      <div class="App-menu">-->
<!--        <button class="App-menuItemAction"-->
<!--                (click)="navigateTo('players/lists')"-->
<!--        >-->
<!--          <fplh-icon class="App-menuItemActionIcon" [config]="{name: 'list', size: 'medium'}"></fplh-icon>-->
<!--          <span class="App-menuItemActionLabel">Lists</span></button>-->
<!--      </div>-->
    </div>
    <div class="App-languageSelectorContainer">
      <fplh-language-selector  *ngIf="!isActionDisabled"></fplh-language-selector>
    </div>
  </div>
  <div id="uniqueIdForAppContent" class="App-content">
    <div class="App-routerOutletContainer">
      <router-outlet></router-outlet>
    </div>
  </div>
  <fplh-footer></fplh-footer>
</div>
