import { Injectable } from '@angular/core';
import {AnimationsService} from '../animations.service';
import {
  animate,
  animation,
  AnimationReferenceMetadata,
  AnimationTransitionMetadata,
  keyframes,
  style,
  transition,
  useAnimation
} from '@angular/animations';

@Injectable()
export class AnimationsHelper {

  private _animationsService: AnimationsService;

  constructor(animationsService: AnimationsService) {
    this._animationsService = animationsService;
  }

  public fadeIn(stateChanger: string): AnimationTransitionMetadata {
    if (this._animationsService.on) {
      const fadeInAnimation: AnimationReferenceMetadata = animation(
          [
            style({ opacity: 1 }),
            animate('{{duration}}')
          ]
      );
      
      return transition(stateChanger, [useAnimation(fadeInAnimation)]);
    }
  }

  public fadeOut(): any {
    if (this._animationsService.on) {
      animation(
          [
            style({ opacity: 0 })
          ]
      );
    }
  }
}
