import {Component, Input, OnInit} from '@angular/core';
import {IContentNavBarConfig} from '../../../../ux/presentation-components/content-nav-bar/interfaces/content-nav-bar.interface';
import {ModelInstance} from '../../../../core/model-instance/model-instance';
import {IPlayersList} from '../../../../core/model-instance/interfaces/model-instance.interfaces';
import {IPlayerInstance} from '../../../../stats/player-instance/interfaces/player-instance.interface';
import {PlayerInstance} from '../../../../stats/player-instance/player-instance';
import {
  ITabbedListNavigation
} from '../../../../ux/presentation-components/tabbed-list/tabbed-list-navigation/interfaces/tabbed-list-navigation.interface';

@Component({
  selector: 'fplh-left-pane',
  templateUrl: './left-pane.component.html',
  styleUrls: ['./left-pane.component.scss']
})
export class LeftPaneComponent implements OnInit {
  @Input()
  public config: ModelInstance<IPlayersList>;

  public contentNavBarConfig: IContentNavBarConfig;
  public topTenData: any;
  public topTenList: IPlayerInstance[];
  public topTenNavigation: ITabbedListNavigation[];
  public isGameWeek = true;

  private _activeView = 'gameWeekPoints';

  public ngOnInit() {
    this._setContentNavBarConfig();

    if (this.config) {
      const mostPoints: IPlayerInstance[] = this.config.getMostPoints();
      const gameWeekPoints: IPlayerInstance[] = this.config.getMostGameWeekPoints();
      const ttd = {
        ...this.config.getTopTenPlayers(),
        gameWeekPoints,
        mostPoints
      };
      this.topTenData = ttd;
      this.topTenList = gameWeekPoints;
      this.topTenNavigation = this._setTopTenNavigation();
    }
  }

  public loadView(view: string): void {
    this.isGameWeek = view === 'gameWeekPoints';

    if (this._activeView !== view) {
      this.topTenList = this.topTenData[view];
      this._activeView = view;
      this.contentNavBarConfig.subHeading = `topPlayers.playerPointsSubHeading.${view}`;
    }
  }

  public onImageError(player: PlayerInstance): void {
    player.image = 'placeholder.png';
  }

  private _setContentNavBarConfig(): void {
    this.contentNavBarConfig = {
      heading: 'topPlayers.topTen.heading',
      subHeading: 'topPlayers.topTen.subHeading.label'
    };
  }

  private _setTopTenNavigation(): ITabbedListNavigation[] {
    return [
      {
        name: 'gameWeekPoints',
        actionConfig: {
          action: (viewName: string): void => {
            this.loadView(viewName);
          },
          translateId: 'gameWeekPoints'
        }
      },
      {
        name: 'mostPoints',
        actionConfig: {
          action: (viewName: string): void => {
            this.loadView(viewName);
          },
          translateId: 'mostPoints'
        }
      },
      {
        name: 'goalkeepers',
        actionConfig: {
          action: (viewName: string): void => {
            this.loadView(viewName);
          },
          translateId: 'playerPositions.goalkeeper.plural'
        }
      },
      {
        name: 'defenders',
        actionConfig: {
          action: (viewName: string): void => {
            this.loadView(viewName);
          },
          translateId: 'playerPositions.defender.plural'
        }
      },
      {
        name: 'midfielders',
        actionConfig: {
          action: (viewName: string): void => {
            this.loadView(viewName);
          },
          translateId: 'playerPositions.midfielder.plural'
        }
      },
      {
        name: 'forwards',
        actionConfig: {
          action: (viewName: string): void => {
            this.loadView(viewName);
          },
          translateId: 'playerPositions.forward.plural'
        }
      }
    ];
  }
}
