import { Routes } from '@angular/router';
import { otherRoutes } from './other/other-routes';
import { adminRoutes } from './admin/admin-routes';
import { statsRoutes } from './stats/stats-routes';

export const appRoutes: Routes = [
  ...otherRoutes,
  ...adminRoutes,
  ...statsRoutes,
  {
    path: '**',
    redirectTo: '/'
  }
];
