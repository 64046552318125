<fplh-card class="PlayerCard-card" [config]="{action: config.actions && config.actions.onCardClick, actionEvent: config.player, settings: config.settings}">
    <!-- Extract into own component -->
    <div class="PlayerCard-viewMoreContainer">
        <button *ngIf="config.actions && config.actions.viewMorePlayers" class="PlayerCard-viewMoreAction"
                (click)="config.actions.viewMorePlayers(config.player)"
                [class.PlayerCard--goalKeeper]="config.player.getPosition().toLowerCase() === 'goalkeeper'"
                [class.PlayerCard--defender]="config.player.getPosition().toLowerCase() === 'defender'"
                [class.PlayerCard--midfielder]="config.player.getPosition().toLowerCase() === 'midfielder'"
                [class.PlayerCard--attacker]="config.player.getPosition().toLowerCase() === 'forward'"
        >
            {{'actions.viewMore' | translate}}
        </button>
    </div>
    <div class="PlayerCard-playerBio">
        <div class="PlayerCard-imageContainer">
          <fplh-image class="PlayerCard-imageComponent" [config]="{src: config.player.getImage(), rounded: true}"></fplh-image>
        </div>
        <div class="PlayerCard-playerName">
            <span class="PlayerCard-playerNameText">{{config.player.firstName + ' ' + config.player.lastName}}</span>
        </div>
        <div class="PlayerCard-playerClub">
            <span class="PlayerCard-playerClubText">{{config.player.club.name}}</span>
        </div>
        <div class="PlayerCard-playerPosition">
            <span class="PlayerCard-positionText">{{'playerPositions.' + config.player.getPosition().toLowerCase() + '.singular' | translate}}</span>
        </div>
        <div class="PlayerCard-playerCost">
            <span class="PlayerCard-costText">{{config.player.cost | currency:'GBP' :'symbol':'1.0'}}m</span>
        </div>
    </div>
    <div class="PlayerCard-statsContainer">
        <h3 class="PlayerCard-pointsScored">{{config.player.points}}<span class="PlayerCard-pointsScoredSuffix">pts</span></h3>

        <span *ngIf="config.settings && config.settings.showPositionLabel && config.player.points === config.mostPointsScored"
              class="PlayerCard-pointsScoredLabel PlayerCard--mostPointsScored"
        >
        {{'mostPoints' | translate}}
      </span>
        <span *ngIf="config.settings && config.settings.showPositionLabel && config.player.points !== config.mostPointsScored"
              class="PlayerCard-pointsScoredLabel"
              [class.PlayerCard--goalKeeper]="config.player.getPosition().toLowerCase() === 'goalkeeper'"
              [class.PlayerCard--defender]="config.player.getPosition().toLowerCase() === 'defender'"
              [class.PlayerCard--midfielder]="config.player.getPosition().toLowerCase() === 'midfielder'"
              [class.PlayerCard--attacker]="config.player.getPosition().toLowerCase() === 'forward'"
        >
        {{'mostPointsScored.by.' + config.player.getPosition().toLowerCase() | translate}}
      </span>
    </div>
</fplh-card>
