<div class="MenuIConSvg-container">
  <svg
    *ngIf="config.icon === 'menu'"
    class="MenuIConSvg-icon"
    version="1.1" x="0px" y="0px" viewBox="0 0 384 384" style="enable-background:new 0 0 384 384;" xml:space="preserve">
    <g>
      <g>
        <g>
          <rect x="0" y="277.333" width="384" height="42.667"/>
          <rect x="0" y="170.667" width="384" height="42.667"/>
          <rect x="0" y="64" width="384" height="42.667"/>
        </g>
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
  <svg *ngIf="config.icon === 'close'"
       [class.MenuIConSvg--white]="(config.color && config.color.name) === 'white'"
       class="MenuIConSvg-icon" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>
</div>

