<div class="Menu-container">
  <button (click)="onMenuClick()" class="Menu-action">
    <fplh-menu-icon-svg *ngIf="showMenuIcon" [config]="{icon: 'menu'}"></fplh-menu-icon-svg>
  </button>
  <ng-container *ngIf="showMenu">
    <div class="Menu-itemsContainerBackdrop"
         (click)="onMenuClick()"
         [@fadeInOutAnimation]></div>
    <div class="Menu-itemsContainer"
         [@enterLeaveAnimation]>
      <div class="Menu-closeActionContainer">
        <button class="Menu-action Menu-closeAction"
                (click)="onMenuClick()">
          <fplh-menu-icon-svg class="Menu-closeActionIcon" [config]="{icon: 'close', color: {name: 'white'}}" *ngIf="showCloseIcon"></fplh-menu-icon-svg>
          <span class="Menu-closeActionIconText">Close Menu</span>
        </button>
      </div>
      <button class="Menu-itemAction"
              *ngFor="let item of menuItems"
              (click)="performMenuItemAction(item)">
        <span class="Menu-itemName">{{item.name}}</span>
      </button>
    </div>
  </ng-container>
</div>
