import {Component, Input, OnInit} from '@angular/core';
import {ITabbedListConfig} from './interfaces/tabbed-list.interface';
import {PlayerInstance} from '../../../stats/player-instance/player-instance';
import {IPlayerInstance} from '../../../stats/player-instance/interfaces/player-instance.interface';
import {PlayersService} from '../../../stats/services/players/players.service';
import {Router} from '@angular/router';

@Component({
  selector: 'fplh-tabbed-list',
  templateUrl: './tabbed-list.component.html',
  styleUrls: ['./tabbed-list.component.scss']
})
export class TabbedListComponent implements OnInit {
  @Input()
  config: ITabbedListConfig;

  private _playersService: PlayersService;
  private _router: Router;

  constructor(playersService: PlayersService, router: Router) {
    this._playersService = playersService;
    this._router = router;
  }

  public ngOnInit() {}

  public onImageError(player: PlayerInstance): void {
    player.image = 'placeholder.png';
  }

  public navigateTo(player: IPlayerInstance) {
    this._playersService.playerDetails = player;
    this._router.navigateByUrl('/players/player/details').then(
      (success: boolean): void => {
      },
      (): void => {

      }
    );
  }
}
