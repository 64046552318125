import {Component, Input, OnInit} from '@angular/core';
import {ITabbedListNavigationConfig} from './interfaces/tabbed-list-navigation.interface';

@Component({
  selector: 'fplh-tabbed-list-navigation',
  templateUrl: './tabbed-list-navigation.component.html',
  styleUrls: ['./tabbed-list-navigation.component.scss']
})
export class TabbedListNavigationComponent implements OnInit {
  @Input()
  public config: ITabbedListNavigationConfig;
  public activeTab = 'gameWeekPoints';
  public isActive = false;
  constructor() { }

  public ngOnInit() {
  }

  public invokeAction(action: any, name: string) {
    this.activeTab = name;
    action(name);
  }

}
