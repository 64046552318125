import {Component, Input, OnInit} from '@angular/core';
import {IRightPaneConfig} from './right-pane/interfaces/right-pane.interface';
import {IIndividualStats, IPlayerInstance} from '../../../stats/player-instance/interfaces/player-instance.interface';
import {IPaneConfig} from './interfaces/pane.interface';
import {IClubTotal} from '../../../shared/interfaces/shared.interface';

@Component({
  selector: 'fplh-pane',
  templateUrl: './pane.component.html',
  styleUrls: ['./pane.component.scss']
})
export class PaneComponent implements OnInit {
  @Input()
  public config: IPaneConfig;

  public rightPane: IRightPaneConfig;
  public constructor() { }

  public ngOnInit() {
    this._setTopStats();
  }

  public _setTopStats(): any {
    const {assists, inform, mostCleanSheetsDEF, mostCleanSheetsGK, mostGoals}: IIndividualStats = this.config.rightPane.topStats;

    this.rightPane = {
      topStats: {
        assists, inform, mostCleanSheetsDEF, mostCleanSheetsGK, mostGoals
      },
      clubTotals: this.config.rightPane.clubTotals
    };

  }
}
