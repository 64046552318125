const iconsList: string[] = [
  'close',
  'en-GB',
  'en-US',
  'germany',
  'uganda',
  'back',
  'premier-league-lion',
  'sort-arrow',
  'list',
  'search'
];

export default iconsList;
