import { Injectable } from '@angular/core';
import { I18NextService } from 'angular-i18next';
import {ITranslate} from './interfaces/ITranslate';
import setTranslationOptions from '../../config/translation.config';
import fallbackLng from '../../config/fallback-language';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  public selectedLanguage = 'en';

  private _i18NextService: I18NextService;

  constructor(i18NextService: I18NextService) {
    this._i18NextService = i18NextService;
  }

  public translate(config: ITranslate): any {
    return this._i18NextService.t(config.translateId);
  }

  public onLanguageChange(language: any, component) {
    sessionStorage.setItem('fallbackLng', JSON.stringify({default: language.code}));
    sessionStorage.setItem('currentLng', JSON.stringify(language));

    this._i18NextService.changeLanguage(language.code).then((x: any): void => {
      document.location.reload();
    });
  }
}
