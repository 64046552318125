import {Injectable} from '@angular/core';
import {enterLeaveAnimation, fadeInOutAnimation} from './animations.triggers';

@Injectable()
export class AnimationsService {
    public on = false;
    private animationTriggers = {
      enterLeaveAnimation,
      fadeInOutAnimation
    };

    constructor() {
        this._getAnimationsSettings();
    }

    public setAnimations(isOn: boolean): void {
        const animationsSettings: any = {
            on: isOn
        };

        this.on = animationsSettings.on;
        localStorage.setItem('animationsSettings', JSON.stringify(animationsSettings));
    }

    public getAnimation(animationName: string): any {
      return this.animationTriggers[animationName];
    }

    private _getAnimationsSettings(): void {
        const animationsSettings: any = JSON.parse(localStorage.getItem('animationsSettings'));

        if (animationsSettings) {
            this.on = animationsSettings.on;
        }
    }
}
