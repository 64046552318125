<div class="Table-container">
  <table class="Table-table">
    <thead class="Table-thead">
    <tr class="Table-theadTr">
      <th class="Table-theadTh"
          *ngFor="let header of config.header.items;" [class.isStretched]="header.isStretched">
        <button class="Table-theadAction" (click)="sortBy(header)">
          <span class="Table-theadActionText">{{'list.headings.' + header.fieldId | translate}}</span>
          <fplh-icon class="Table-theadActionIcon"
                     [class.Table--arrowUp]="showArrowUp"
                     *ngIf="sortByField === header.fieldId || header.sorted && header.fieldId.toLowerCase() === sortByField.toLowerCase() "
                     [config]="{ name: 'sort-arrow', size: 'xxSmall' }">
          </fplh-icon>
        </button>
      </th>
    </tr>
    </thead>
    <tbody class="Table-tbody">
    <tr class="Table-tbodyTr"
        *ngFor="let item of config.body.items; let even = even;">
      <td class="Table-tbodyTd"
          [class.isHighlightedRow]="!even"
          *ngFor="let field of config.header.items; let first = first" [attr.data-label]="field.text">
        <div class="Table-imageContainer" *ngIf="first">
          <fplh-image [config]="{src: item['image']}"></fplh-image>
        </div>
        <span class="Table-tbodyTdTextMobile" [class.hasImage]="first && !!item['image']">{{setMobileFieldName(field.fieldId)}} </span>
        <span class="Table-tbodyTdText" [class.hasImage]="first && !!item['image']">{{item[field.fieldId] }}</span>
      </td>
    </tr>
    </tbody>
  </table>
</div>
