import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AnimationsService} from './services/animations/animations.service';
import {AnimationsSettingsComponent} from './business-components/animations-settings/animations-settings.component';
import {AnimationsHelper} from './services/animations/helper/animations-helper.service';
import {TranslationModule} from '../translation/translation.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AnimationsSettingsComponent
  ],
  exports: [
    AnimationsSettingsComponent
  ],
  imports: [
    CommonModule,
    TranslationModule,
    BrowserAnimationsModule,
  ],
  providers: [
    AnimationsService,
  ]
})
export class AnimationsModule {
  static forRoot(config: AnimationsHelper): ModuleWithProviders<AnimationsModule> {
    return {
      ngModule: AnimationsModule,
      providers: [
        {provide: AnimationsHelper, useValue: config }
      ]
    };
  }
}
