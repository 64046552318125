<div class="Club-container" fplhAppContainerCssOverride>
  <div class="Club-loadingSpinnerBackdrop" *ngIf="isLoading">
    <fplh-loading-spinner
      class="Club-spinner"
      [colorScheme]="'colorGreyLight'"></fplh-loading-spinner>
  </div>
  <div class="Club-header"
       *ngIf="club && club.name"
       [ngClass]="clubThemeColor">
    <div class="Club-headerContent">
      <div class="Club-logoContainer">
        <div class="Club-imageBackdrop">
          <img class="Club-clubImage" [src]="'assets/images/clubs/' + club.name + '.png' " [alt]="club.name">
        </div>
      </div>
      <div class="Club-info">
        <div class="Club-headingContainer">
          <h1>{{club.name}}</h1>
          <h1>{{totalPoints}} Points</h1>
        </div>
      </div>
    </div>
    <div class="Club-optionsContainer">
      <button class="Club-option"
              *ngFor="let option of options" (click)="option.action(option)"
              [class.Club--activeOption]="activeOption === option.name">
        <span class="Club-optionTextMobile">{{option.name.charAt(0)}}</span>
        <span class="Club-optionTextDesktop">{{option.name}}</span>
      </button>
    </div>
  </div>

  <div class="Club-contentContainer">
    <div class="Club-contentHeaderContainer">
      <h1 class="Club-contentHeader">{{tableHeader}}</h1>
    </div>
    <div class="Club-table">
      <fplh-table *ngIf="tableConfig" [config]="tableConfig"></fplh-table>
    </div>
  </div>
</div>
