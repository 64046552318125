import {Directive, OnDestroy, OnInit} from '@angular/core';

@Directive({
  selector: '[fplhAppContainerCssOverride]'
})
export class AppContainerCssOverrideDirective implements OnInit, OnDestroy {
  public container: HTMLDivElement;

  constructor() {
  }

  public ngOnInit(): void {
    const container: HTMLDivElement = document.getElementById('uniqueIdForAppContent') as HTMLDivElement;



    if (container && !container.classList.contains('App--noAutoCentring')) {
      this.container = container;
      container.classList.add('App--noAutoCentring');
    }
  }

  public ngOnDestroy(): void {
    if (this.container && this.container.classList.contains('App--noAutoCentring')) {
      this.container.classList.remove('App--noAutoCentring');
    }
  }

}
