import {Directive, ElementRef, OnInit} from '@angular/core';

@Directive({
  selector: '[fplhFixedOnScroll]'
})
export class FixedOnScrollDirective implements OnInit {

  private _elementRef: ElementRef;
  private _elementToFixOnScroll: HTMLElement;
  constructor(elementRef: ElementRef) {
    this._elementRef = elementRef;
    this._elementToFixOnScroll = elementRef.nativeElement;
  }

  public ngOnInit() {
    const elementOffset: number = this._elementToFixOnScroll.offsetTop;

    this._initialiseOnScroll(elementOffset);
  }

  private _initialiseOnScroll(elemOffset: number): void {
    window.onscroll = (): void => {
      const hasClassAlready = this._elementToFixOnScroll.classList.contains('App--fixedNavigation');

      if ((window.pageYOffset > elemOffset) && !hasClassAlready) {
        this._elementToFixOnScroll.classList.add('App--fixedNavigation');

      } else {
        if ((window.pageYOffset <= elemOffset) && hasClassAlready) {
          this._elementToFixOnScroll.classList.remove('App--fixedNavigation');
        }
      }
    };
  }
}
