import { Injectable } from '@angular/core';
import {IPlayerPosition} from './interfaces/players.service.interface';
import {IPlayerInstance} from '../../player-instance/interfaces/player-instance.interface';
import {ITopTenPlayersByPosition} from '../../../core/model-instance/interfaces/model-instance.interfaces';
import {PlayerInstance} from '../../player-instance/player-instance';

@Injectable()
export class PlayersService {

  public playerDetails: IPlayerInstance;
  private _list: ITopTenPlayersByPosition;

  public onInit(playersList: ITopTenPlayersByPosition): void {
    this._list = playersList;
  }

  public getPlayerPosition(player: IPlayerInstance): IPlayerPosition {
    return {
      name: player.position.name,
      abbreviation: player.position.abbreviation // Refactor - change appropriate abv
    };
  }

  public getTopPlayersByPosition(config: {quantity: number, position: string}): any {
    const index: number = config.quantity - 1;
    const poi = config.position + 's'; // Refactor this
    const filtered: IPlayerInstance[] = this._list[poi];

    return config.quantity === 1 ? filtered[index] : filtered.slice(0, config.quantity);
  }

  // ToDo - use correct interface
  public assignPlayerInstance(player: any): IPlayerInstance {
    return Object.assign(new PlayerInstance(), player);
  }
}
