import {animate, style, transition, trigger} from '@angular/animations';

export const enterLeaveAnimation = trigger(
  'enterLeaveAnimation',
  [
    transition(
      ':enter',
      [
        style({ left: -100 }),
        animate('200ms ease-out',
          style({ left: 0 }))
      ]
    ),
    transition(
      ':leave',
      [
        style({ left: 0}),
        animate('200ms ease-in',
          style({ left: -100}))
      ]
    )
  ]
);

export const fadeInOutAnimation = trigger(
  'fadeInOutAnimation',
  [
    transition(
      ':enter',
      [
        style({ opacity: 0 }),
        animate('200ms ease-out',
          style({ opacity: 100 }))
      ]
    ),
    transition(
      ':leave',
      [
        style({ opacity: 100}),
        animate('200ms ease-in',
          style({ opacity: 0 }))
      ]
    )
  ]
);
