import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import { CoreModel } from '../../../core/model/core.model';
import {
  ITableConfig, ITableHeader,
  ITableItems
} from '../../../ux/presentation-components/table/interfaces/table.interface';
import { IAllPlayers } from '../../../core/model-instance/interfaces/model-instance.interfaces';
import { TranslationService } from '../../../translation/services/translation/translation.service';
import {IPlayer} from '../../player-instance/interfaces/player-instance.interface';
import {HttpErrorResponse} from '@angular/common/http';
import {FooterService} from "../../../shared/services/footer/footer.service";


@Component({
  selector: 'fplh-lists',
  templateUrl: './lists.component.html',
  styleUrls: ['./lists.component.scss']
})
export class ListsComponent implements OnInit, AfterViewInit, OnDestroy {
  public menuItems: any[];
  public allPlayers: IPlayer[];
  public tableConfig: ITableConfig;
  public activeMenuItem = '';
  public currentPage = 1;
  public totalPlayers = 0;
  public currentlyLoaded = 0;
  public isLoading = false;
  public topTen: IPlayer[];
  public topFifty: IPlayer[];
  public showLoadMore = false;
  public isLoadMoreDisabled = false;
  public isError = false;
  public message = 'An error occurred';

  private _model: CoreModel;
  private _totalNumber = 0;
  private _translationService: TranslationService;
  private _fs: FooterService;

  constructor(model: CoreModel, translationService: TranslationService, fs: FooterService) {
    this._model = model;
    this._translationService = translationService;
    this._fs = fs;
  }

  public ngOnInit() {
    this._getPlayersData();
    this.activeMenuItem = this._translationService.translate({translateId: 'listMenu.all'});
    this.menuItems = this._getMenuItems();
  }

  public ngOnDestroy() {
    this._fs.resetPosition();
  }

  public  ngAfterViewInit() {
    this._fs.positionBottom();
  }

  public onMenuItemClick(menuItem: any) {
    menuItem.action();
    menuItem.isActive = true;
    this.activeMenuItem = menuItem.label;
  }

  public loadMorePlayers() {
    this.currentPage++;
    this._getPlayersData(this.currentPage);
  }

  private _getPlayersData(pageNo: number = 1): void {
    this.isLoading = true;
    this._model.getAllPlayers((`Players/Lists?pageNo=${pageNo}&pageSize=100`)).subscribe(
      (response: IAllPlayers): any => {

        if (response && response.data) {
          const list: IPlayer[] = response.data.list;
          const totalNumberOfPlayers = response.data.count;

          if (list) {
            if (pageNo === 1) {
              this.allPlayers = list;
              this.currentlyLoaded = this.allPlayers.length;
              this._totalNumber = totalNumberOfPlayers;
              this.totalPlayers = this._totalNumber;

              this.showLoadMore = true;
            } else if (pageNo > 1) {

              this.allPlayers = this.allPlayers.concat(list);

              this.currentlyLoaded = this.allPlayers.length;

              this.isLoadMoreDisabled = this.currentlyLoaded === this.totalPlayers;

              this.showLoadMore = true;
            }
          }

          this._populateTable();
          this.isLoading = false;
          this._fs.resetPosition();
        }
      },
      (errorResponse: HttpErrorResponse): void => {
        const error = errorResponse.error;

        this.isLoading = false;
        this.isError = true;
      }
    );
  }

  private _getMenuItems(): any[] {
    return [
      {
        label: this._translationService.translate({translateId: 'listMenu.all'}),
        action: (menuItem: any): void => {
          this._populateTable(false, false);
          this.totalPlayers = this._totalNumber;
          this.currentlyLoaded = this.allPlayers.length;
          this.showLoadMore = true;
        },
        isActive: true
      },
      {
        label: this._translationService.translate({translateId: 'listMenu.topTen'}),
        action: (menuItem: any): void => {
          this.showLoadMore = false;
          this.topTen = this.allPlayers.slice(0, 10);
          this.currentlyLoaded = this.topTen.length;
          this.totalPlayers = this.topTen.length;
          this._populateTable(true);
        },
        isActive: false
      },
      {
        label: this._translationService.translate({translateId: 'listMenu.topFifty'}),
        action: (): void => {
          this.topFifty = this.allPlayers.slice(0, 50);
          this.currentlyLoaded = this.topFifty.length;
          this.totalPlayers = this.topFifty.length;
          this._populateTable(false, true);
          this.showLoadMore = false;
        },
        isActive: false
      }
    ];
  }

  private _populateTable(isTopTen: boolean = false, isTopFifty: boolean = false): void {
    const tableHeaderItems: ITableHeader = {
      items: [
        {
          fieldId: 'name',
          isStretched: true,
          type: 'string'
        },
        {
          fieldId: 'club',
          type: 'string'
        },
        {
          fieldId: 'position',
          type: 'string'
        },
        {
          fieldId: 'points',
          sorted: true,
          type: 'number'
        },
        {
          fieldId: 'cost',
          type: 'number'
        },
        {
          fieldId: 'form',
          type: 'number'
        },
        {
          fieldId: 'goalsScored',
          type: 'number'
        },
      ]
    };

    let _list: IPlayer[] = this.allPlayers;

    if (isTopTen) {
      _list = this.topTen;
    }

    if (isTopFifty) {
      _list = this.topFifty;
    }

    if (!isTopTen && !isTopFifty) {
      _list = this.allPlayers;
    }
    const tableBodyItems: ITableItems<any[]> = {
      items: _list.map((player: IPlayer): any => {

        return {
          name: `${player.firstName} ${player.lastName}`,
          club: player.club.name,
          position: player.position.name,
          form: player.form,
          image: player.image,
          cost: `£${player.cost}`,
          points: player.points,
          goalsScored: player.goalsScored,
        };
      })
    };

    this.tableConfig = {
      header: tableHeaderItems,
      body: tableBodyItems
    };
  }

}
