import { Component, OnInit } from '@angular/core';
import {TranslationService} from '../../../translation/services/translation/translation.service';
import {I18NextService} from 'angular-i18next';

@Component({
  selector: 'fplh-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {

  private _translationService: TranslationService;
  private _i18NextService: I18NextService;

  constructor(translationService: TranslationService, i18NextService: I18NextService) {
    this._translationService = translationService;
    this._i18NextService = i18NextService;
  }

  public showPopup = false;

  public selectedLanguage: any;

  public supportedLanguages: any[] = [
    {
      code: 'en',
      label: 'English',
      icon: 'en-GB',
      ns: 'en-GB'
    },
    {
      code: 'ge',
      label: 'German',
      icon: 'germany'
    },
    {
      code: 'lu',
      label: 'Luganda',
      icon: 'uganda',
      ns: 'uganda'
    }
  ];

  public ngOnInit() {
    const selectLanguageSettings: any = JSON.parse(sessionStorage.getItem('currentLng'));

    if (selectLanguageSettings) {
      this.selectedLanguage = selectLanguageSettings;
    } else {
      this.selectedLanguage = {
        code: 'en',
        label: 'English',
        icon: 'en-GB'
      };
    }
  }

  public toggleLanguages(): void {
    this.showPopup = !this.showPopup;
  }

  public onLanguageClick(selected: any): void {
    this.selectedLanguage = selected;
    this.showPopup = false;

    this._translationService.onLanguageChange(selected, this);
  }

}
