import { Component, OnInit } from '@angular/core';
import {CoreModel} from '../../../core/model/core.model';
import {ActivatedRoute, Router} from '@angular/router';
import {IPlayer, IPlayerClub} from '../../player-instance/interfaces/player-instance.interface';
import {
  ITableConfig,
  ITableHeader,
  ITableItems
} from '../../../ux/presentation-components/table/interfaces/table.interface';

@Component({
  selector: 'fplh-club',
  templateUrl: './club.component.html',
  styleUrls: ['./club.component.scss']
})
export class ClubComponent implements OnInit {
  public players: IPlayer[] = [];
  public club: IPlayerClub;
  public options: {name: string; action: (a: any) => void}[];
  public tableConfig: ITableConfig;
  public clubThemeColor = '';
  public activeOption = 'All';
  public isLoading = true;
  public totalPoints = 0;
  public tableHeader = 'All players';

  private _coreModel: CoreModel;
  private _router: Router;
  private _activatedRoute: ActivatedRoute;

  constructor(cm: CoreModel, router: Router, ar: ActivatedRoute) {
    this._coreModel = cm;
    this._router = router;
    this._activatedRoute = ar;
  }

  public ngOnInit(): void {
    const id = this._activatedRoute.snapshot.paramMap.get('id');

    if (id) {
      this._coreModel.get<IPlayer[]>(`clubs/club/player?id=${id}`).subscribe(
        (response: any): void => {
          this.players = response.data;
          this.club = this.players[0].club;

          this.totalPoints = this._calculateTotalPoints();
          const clubName = this.club.name;
          this.clubThemeColor = 'Club--' + clubName.charAt(0).toLowerCase() + clubName.slice(1).replace(' ', '');

          this.options = [
            {
              name: 'All',
              action: (a: any): void => {
                this.activeOption = a.name;
                this._configureTable(this.players);
                this.tableHeader = 'All players';
              }
            },
            {
              name: 'Goalkeepers',
              action: (a: any): void => {
                this.activeOption = a.name;
                this.tableHeader = 'Goalkeepers';
                const gks = this.players.filter((p: IPlayer): boolean => {
                  return p.position.name.toLowerCase() === 'goalkeeper';
                });

                this._configureTable(gks);
              }
            },
            {
              name: 'Defenders',
              action: (a: any): void => {
                this.activeOption = a.name;
                this.tableHeader = 'Defenders';

                const dfs = this.players.filter((p: IPlayer): boolean => {
                  return p.position.name.toLowerCase() === 'defender';
                });

                this._configureTable(dfs);
              }
            },
            {
              name: 'Midfielders',
              action: (a: any): void => {
                this.activeOption = a.name;
                this.tableHeader = 'Midfielders';

                const mfs = this.players.filter((p: IPlayer): boolean => {
                  return p.position.name.toLowerCase() === 'midfielder';
                });

                this._configureTable(mfs);
              }
            },
            {
              name: 'Forwards',
              action: (a: any): void => {
                this.activeOption = a.name;
                this.tableHeader = 'Forwards';

                const fws = this.players.filter((p: IPlayer): boolean => {
                  return p.position.name.toLowerCase() === 'forward';
                });

                this._configureTable(fws);
              }
            }
          ];

          this._configureTable(this.players);

          this.isLoading = false;
        },
        (err: any): void => {
          this._router.navigateByUrl('/');
        }
      );
    } else {
      this._router.navigateByUrl('/');
    }
  }

  private _configureTable(dataSource: IPlayer[]): void {
    const tableHeaderItems: ITableHeader = {
      items: [
        {
          fieldId: 'name',
          isStretched: true,
          type: 'string'
        },
        {
          fieldId: 'position',
          type: 'string'
        },
        {
          fieldId: 'points',
          sorted: true,
          type: 'number'
        },
        {
          fieldId: 'cost',
          type: 'number'
        },
        {
          fieldId: 'form',
          type: 'number'
        },
        {
          fieldId: 'goalsScored',
          type: 'number'
        },
      ]
    };

    const tableBodyItems: ITableItems<any[]> = {
      items: dataSource.map((player: IPlayer): any => {

        return {
          name: `${player.firstName} ${player.lastName}`,
          club: player.club.name,
          position: player.position.name,
          form: player.form,
          image: player.image,
          cost: `£${player.cost}`,
          points: player.points,
          goalsScored: player.goalsScored,
        };
      })
    };

    this.tableConfig = {
      header: tableHeaderItems,
      body: tableBodyItems
    };
  }

  private _calculateTotalPoints(): number {
    let total = 0;

    this.players.forEach((p: IPlayer): void => {
      total += p.points;
    });

    return total;
  }

}
