<div class="Lists-container">
  <fplh-loading-spinner
    *ngIf="isLoading"
    class="Lists-pageLoadSpinner"
    [colorScheme]="'colorGreyLight'"></fplh-loading-spinner>
  <div class="Lists-messageBackdrop" *ngIf="isError && !isLoading">
    <div class="Lists-messageContainer">
      <h1 class="Lists-message">{{message}}</h1>
    </div>
  </div>
  <div *ngIf="tableConfig" class="Lists-menuContainer">
    <div class="Lists-menu">
      <button class="Lists-menuItem"
              *ngFor="let menu of menuItems"
              [class.isActive]="menu.label === activeMenuItem"
              (click)="onMenuItemClick(menu)">
        {{menu.label}}
      </button>
    </div>
  </div>
  <div class="Lists-totalCountContainer" *ngIf="!isLoading && !isError">
    <h1 class="Lists-totalCount">{{currentlyLoaded}}/{{totalPlayers}}</h1>
  </div>
  <fplh-table *ngIf="tableConfig" [config]="tableConfig"></fplh-table>
  <div *ngIf="!isLoading && showLoadMore" class="Lists-loadMoreContainer">
    <button class="Lists-loadMoreAction"
            [class.isDisabled]="isLoadMoreDisabled"
            (click)="loadMorePlayers()" [disabled]="isLoadMoreDisabled">Load More</button>
  </div>
</div>
