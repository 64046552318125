import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'fplh-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {
  @Input()
  public config: {action: (event: any) => void, actionEvent: any, settings?: {isTopFive?: boolean}};
  constructor() { }

  public ngOnInit() {}

}
